import { useEffect, useContext, useState } from "react";
import "./index.scss";
import SelectToken from "./selectToken";
import { networkConfig } from "constan";
import { EnvContextParams, EnvContext } from "App";
import useNotification from "hooks/useNotification";
import { Address as Address1 } from "@ton/ton";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useParams, useNavigate } from "react-router-dom";
import { Address, toNano } from "ton";
import { poolDeployController } from "lib/deploy-controller";
import { DEX, pTON } from "@ston-fi/sdk";
import Request from "components/Axios";
import { getClientNow } from "lib/get-ton-client";
import { throttle } from "radash";

export default function AddPool() {
  const { network, clear }: EnvContextParams = useContext(EnvContext);
  const walletAddress = useTonAddress();
  const [tonconnect] = useTonConnectUI();
  const { showNotification } = useNotification();
  const [isStatus, setIsStatus] = useState(false);
  const [code, setCode] = useState("");
  const [poolAddress, setPoolAddress] = useState("");
  const [selectToken, setSelectToken] = useState("");
  const params = useParams();
  const history = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const { allAxios } = Request();
  const getPool = async (allToken: any, isShow: boolean) => {
    try {
      const client = await getClientNow(network);
      const router = client.open(
        new DEX.v1.Router(Address1.parse(networkConfig[network].routerAddress)),
      );
      const pool = await client.open(
        await router.getPool({
          token0: Address.parse(allToken?.tokenA?.jetton?.address).toFriendly(),
          token1: Address.parse(allToken?.tokenB?.jetton?.address).toFriendly(),
        }),
      );
      const poolAddress = pool?.address?.toString();
      const owen = Address.parse(walletAddress).toFriendly();
      if (poolAddress) {
        setPoolAddress(poolAddress);
        setSelectToken(allToken?.tokenA?.jetton?.address);
        //    判断   pool是否存在
        const data: any = await allAxios({
          method: "get",
          url: "/api/v1/ton/pool/exists",
          params: {
            poolAddress: poolAddress,
            ownerAddress: owen,
            network,
          },
          network,
        });
        if (data === "clear" || data?.response?.data?.msg === "invalid signature") {
          clear("have");
        } else if (data?.status === 200) {
          await poolDeployController.provideLiquidity(
            tonconnect,
            isShow ? params?.id : Address.parse(allToken?.tokenA?.jetton?.address).toFriendly(),
            Address.parse(allToken?.tokenB?.jetton?.address).toFriendly(),
            toNano(Number(allToken?.tokenA?.value)).toString(),
            toNano(Number(allToken?.tokenB?.value)).toString(),
            networkConfig[network].routerAddress,
            walletAddress,
            networkConfig[network].ptonAddress,
            network,
          );
          // 是否有结果
          if (data?.data?.message === "false") {
            const createOrder: any = await allAxios({
              method: "post",
              url: "/api/v1/ton/pool/createOrder",
              data: {
                token0Address: Address.parse(allToken?.tokenA?.jetton?.address).toFriendly(),
                token1Address: Address.parse(allToken?.tokenB?.jetton?.address).toFriendly(),
                poolAddress: poolAddress,
                ownerAddress: owen,
                network,
              },
              network,
            });
            if (
              createOrder === "clear" ||
              createOrder?.response?.data?.msg === "invalid signature"
            ) {
              clear("have");
            } else if (createOrder?.status === 200 && createOrder?.data?.code === 200) {
              setIsStatus(!isStatus);
              setCode("yes");
            }
          } else {
            setIsStatus(!isStatus);
            setCode("yes");
          }
        }
      }
    } catch (e) {
      setIsSubmit(false);
      return null;
    }
  };
  const onsubmit = throttle({ interval: 3000 }, async (data: any, isShow: boolean) => {
    setIsSubmit(true);
    if (data) {
      await getPool(data, isShow);
    } else {
      setIsSubmit(false);
    }
  });

  const getPoolStatus = async () => {
    const data: any = await allAxios({
      method: "get",
      url: "/api/v1/ton/pool/status",
      params: {
        poolAddress: poolAddress,
        ownerAddress: Address.parse(walletAddress).toFriendly(),
        network,
      },
      network,
    });
    if (data?.status === 200) {
      if (Number(data?.data?.status)) {
        const message = `Successfully create pool`;
        showNotification(message, "success");
        history("/jetton/" + Address.parse(selectToken).toFriendly() + "/pool");
        setIsSubmit(false);
      } else {
        setIsStatus(!isStatus);
      }
    } else if (data === "clear" || data?.response?.data?.msg === "invalid signature") {
      clear("have");
    } else {
      setIsSubmit(false);
    }
  };
  useEffect(() => {
    let time: any = null;
    if (code) {
      time = setTimeout(getPoolStatus, 3000);
    }
    return () => {
      clearTimeout(time);
    };
  }, [isStatus, code]);
  return (
    <SelectToken style={{ width: "50%", margin: "0 auto" }} isSubmit={isSubmit} sure={onsubmit} />
  );
}

import "./index.scss";
import AddPool from "./components/addPool";
import Back from "assets/icons/backRouter.svg";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PairList from "./components/pairList";
import AddLiquidity from "./components/addLiquidity";
import { useTonAddress } from "@tonconnect/ui-react";
import { useTranslation } from "react-i18next";
import { EnvContextParams, EnvContext } from "App";
function Pool() {
  const { pool, id }: { pool?: string; id?: string } = useParams();
  const history = useNavigate();
  const { t } = useTranslation();
  const { openTonConnect, clear, network }: EnvContextParams = useContext(EnvContext);
  const walletAddress = useTonAddress();
  const token = localStorage.getItem("token");
  return (
    <div className="poolBox">
      <p className="poolBack">
        <img
          src={Back}
          alt=""
          onClick={() => {
            if (pool && id) {
              if (pool === "pool") {
                history("/");
              } else if (pool === "addLiquidity") {
                history("/jetton/1/pool");
              } else if (pool === "addPool") {
                history(-1);
              }
            }
          }}
        />
        <span>
          {pool === "pool"
            ? t("page.SetLiquidity")
            : pool === "addPool"
            ? t("page.addLiquidity")
            : t("page.AddLiquidity")}
        </span>
      </p>
      {pool === "pool" && (
        <p
          className="addPool"
          onClick={() => {
            if (token) {
              if (pool && id) {
                history("/jetton/" + id + "/addPool");
              }
            } else {
              if (walletAddress) {
                clear("have");
              } else {
                openTonConnect();
              }
            }
          }}>
          {walletAddress && token ? t("page.AddNew") : t("page.ConnectWallet")}
        </p>
      )}
      {pool === "pool" ? <PairList /> : pool === "addPool" ? <AddPool /> : <AddLiquidity />}
    </div>
  );
}

export { Pool };

import { Typography } from "@mui/material";
import BigNumberDisplay from "components/BigNumberDisplay";
import { Popup } from "components/Popup";
import useNotification from "hooks/useNotification";
import { jettonDeployController } from "lib/deploy-controller";
import { useState, useContext, useEffect } from "react";
import useJettonStore from "store/jetton-store/useJettonStore";
import { AppButton } from "components/appButton";
import { AppNumberInput } from "components/appInput";
import { toDecimalsBN } from "utils";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { EnvContextParams, EnvContext } from "App";
import { useTranslation } from "react-i18next";

import Decimal from "decimal.js";
Decimal.set({ toExpPos: 24, precision: 24 });

function BurnJettonsAction() {
  const { network }: EnvContextParams = useContext(EnvContext);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const {
    jettonMaster,
    symbol,
    getJettonDetails,
    balance,
    jettonWalletAddress,
    isMyWallet,
    decimals,
  } = useJettonStore();

  const { showNotification } = useNotification();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [total, setTotal] = useState<any>(null);
  const [tonconnect] = useTonConnectUI();
  const walletAddress = useTonAddress();
  const { t } = useTranslation();

  const onBurn = async () => {
    if (!jettonMaster) {
      return;
    }

    if (!amount || amount === 0) {
      showNotification(`Minimum amount to burn is 1 ${symbol}`, "warning");
      return;
    }

    const valueDecimals = toDecimalsBN(amount, decimals!);
    const balanceDecimals = toDecimalsBN(balance!!.toString(), decimals!);
    if (valueDecimals.gt(balanceDecimals)) {
      const msg = (
        <>
          Maximum amount to burn is <BigNumberDisplay value={balance} />
        </>
      );
      showNotification(msg, "warning", undefined, 3000);
      return;
    }

    try {
      setActionInProgress(true);
      await jettonDeployController.burnJettons(
        tonconnect,
        valueDecimals,
        jettonWalletAddress!,
        walletAddress,
        network,
      );
      const message = `Successfully burned ${amount.toLocaleString()} ${symbol}`;
      showNotification(message, "success");
      getJettonDetails(network);
    } catch (error) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
      }
    } finally {
      setActionInProgress(false);
      setOpen(false);
    }
  };
  const onClose = () => {
    setAmount(0);
    setOpen(false);
  };

  useEffect(() => {
    if (balance?.toString()) {
      const ttt = new Decimal(balance?.toString()).div(new Decimal(10).pow(decimals));
      setTotal(ttt);
    }
  }, [balance]);
  if (!balance || !isMyWallet) {
    return null;
  }
  return (
    <>
      <Popup open={open && !actionInProgress} onClose={onClose} maxWidth={400}>
        <>
          <Typography className="title" style={{ color: "#fff" }}>
            {t("page.Burn")} {symbol}
          </Typography>
          <AppNumberInput
            label={`${t("page.Enter")} ${symbol} ${t("page.amount")}`}
            value={amount}
            status="burn"
            total={Number(total?.toString())}
            onChange={(value: number) => {
              setIsRefresh(!isRefresh);
              setAmount(value || 0);
            }}
          />
          <p style={{ color: " #EA6E6E", marginTop: "5px" }}>{t("page.burnToken")}</p>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <AppButton
              onClick={onClose}
              background="transparent"
              border={"1px solid rgb(134,240,151)"}>
              {t("page.Cancel")}
            </AppButton>
            <AppButton onClick={onBurn}> {t("page.Submit")}</AppButton>
          </div>
        </>
      </Popup>
      <AppButton loading={actionInProgress} transparent={"false"} onClick={() => setOpen(true)}>
        {t("page.Burn")}
      </AppButton>
    </>
  );
}

export default BurnJettonsAction;

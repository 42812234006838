import React from "react";
import { Popup } from "components/Popup";
import { Box, Typography } from "@mui/material";
import { CenteringWrapper } from "components/header/headerSearchBar/styled";
import { AppButton } from "components/appButton";
import openLink from "assets/icons/link-open.svg";
import { PopupLink, PopupTitle } from "components/editLogoPopup/styled";
import { useTranslation } from "react-i18next";
interface LogoAlertPopupProps {
  showPopup: boolean;
  close: () => void;
  onValidate: any;
  isUpdateText: boolean;
}

export const LogoAlertPopup = ({
  showPopup,
  close,
  onValidate,
  isUpdateText,
}: LogoAlertPopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup open={showPopup} onClose={close} maxWidth={592}>
      <PopupTitle style={{ color: "#fff" }}>{t("page.tokenImg")}</PopupTitle>
      <Typography sx={{ alignSelf: "baseline", color: "rgba(255, 255, 255, 0.75)" }} mb={0.5}>
        {t("page.YourToken")} <span style={{ fontWeight: 700 }}>{t("page.does")}</span>
      </Typography>
      <Typography sx={{ alignSelf: "baseline", lineHeight: 2, color: "rgba(255, 255, 255, 0.55)" }}>
        {t("page.can")}
      </Typography>
      <CenteringWrapper mb={2}>
        <CenteringWrapper mr={2}>
          <AppButton height={44} width={98} fontWeight={700} type="button" onClick={close}>
            {t("page.Cancel")}
          </AppButton>
        </CenteringWrapper>
        <AppButton
          height={44}
          width={isUpdateText ? 140 : 98}
          fontWeight={700}
          type="button"
          onClick={() => {
            onValidate();
            close();
          }}>
          {isUpdateText ? t("page.Update") : t("page.Deploy")}
        </AppButton>
      </CenteringWrapper>
    </Popup>
  );
};

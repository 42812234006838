import { styled } from "@mui/material";
import { Box } from "@mui/system";
import "./index.css";
import { createContext, useEffect, useState } from "react";
import { APP_GRID, ROUTES } from "consts";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { DeployerPage, Jetton, Home, TokenList, Pool } from "pages";
import analytics from "services/analytics";
import { Header } from "components/header";
import { useJettonLogo } from "hooks/useJettonLogo";
import BackLeft from "assets/icons/bodyLeftLogo.svg";
import { useTonAddress, useTonConnectUI, useTonConnectModal } from "@tonconnect/ui-react";
import BackRight from "assets/icons/backRight.svg";
import Request from "./components/Axios";
import { useTranslation } from "react-i18next";
import useNotification from "hooks/useNotification";
analytics.init();
export type EnvContextParams = {
  network: string;
  setNetwork: any;
  isSandbox?: boolean;
  openTonConnect?: any;
  clear?: any;
  isConnect: boolean;
  setIsConnect: any;
};
const AppWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  overflowY: "auto",
  backgroundColor: "rgba(33, 33, 33, 1)",
}));

const ScreensWrapper = styled(Box)({
  width: "85%",
  margin: "0 auto",
  "*::-webkit-scrollbar": {
    display: "none",
  },
  "*::-webkit-scrollbar-track": {
    display: "none",
  },
  "*::-webkit-scrollbar-thumb": {
    display: "none",
  },
});

const FlexibleBox = styled(Box)(({ theme }) => ({
  maxWidth: APP_GRID,
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 30px)",
  },
}));
export const EnvContext: any = createContext(null);
const PageNotFound = () => {
  const { showNotification } = useNotification();
  useEffect(() => {
    showNotification("Page not found", "error");
  }, []);
  return <Box />;
};
interface ContentWrapperProps {
  children?: any;
}
const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <FlexibleBox>
      {children}
      <Outlet />
    </FlexibleBox>
  );
};
const App = () => {
  const { showNotification } = useNotification();
  const { resetJetton } = useJettonLogo();
  const [isConnect, setIsConnect] = useState(false);
  const { t } = useTranslation();
  const history = useNavigate();
  const { open: openTon, state } = useTonConnectModal();
  // useEffect(() => {
  //   if (state?.closeReason && state?.status === "closed") {
  //     setIsConnect(false);
  //   }
  // }, [state]);
  const { allAxios } = Request();
  const location = useLocation();
  const openTonConnect = async () => {
    const data = await setParameters();
    if (data) {
      openTon();
    }
  };
  //  mainnet
  const [network, setNetwork] = useState("testnet");
  useEffect(() => {
    resetJetton();
  }, [location.pathname]);
  const [tonWallet, setTonWallet] = useState<any>(null);
  const userFriendlyAddress = useTonAddress();
  useEffect(() => {
    if (userFriendlyAddress && tonWallet?.account) {
      tonConnect();
    }
  }, [userFriendlyAddress, tonWallet]);
  //ton钱包连接
  const tonConnect = async () => {
    const proof = tonWallet?.connectItems?.tonProof?.proof;
    const par = {
      payload: proof?.payload,
      value: proof?.domain.value,
      lengthBytes: proof?.domain.lengthBytes,
      stateInit: tonWallet?.account?.walletStateInit,
      signature: proof?.signature,
      address: userFriendlyAddress,
      timestamp: proof?.timestamp,
      inviteCode: "",
    };
    //   获取  token
    const data: any = await allAxios({
      method: "post",
      url: "/api/v1/ton/login",
      data: par,
      network,
    });
    if (data?.data?.accessToken) {
      localStorage.setItem("token", data?.data?.accessToken);
      setIsConnect(false);
    } else {
      setIsConnect(false);
    }
  };
  const [tonConnectUI] = useTonConnectUI();
  const setParameters = async () => {
    try {
      const noce: any = await allAxios({
        method: "post",
        url: "/api/v1/token",
        data: { address: "" },
        network,
      });
      if (noce?.data?.nonce) {
        tonConnectUI.setConnectRequestParameters({
          state: "ready",
          value: {
            tonProof: noce?.data?.nonce,
          },
        });
        return true;
      } else {
        setIsConnect(false);
        clear("have");
        showNotification(t("page.Service"), "error");
      }
    } catch (e) {
      return null;
    }
  };

  const clear = async (name?: string) => {
    history("/");
    localStorage.removeItem("token");
    if (name) {
      await tonConnectUI.disconnect();
    }
    showNotification(t("page.Login"), "warning");
  };
  //  监听ton的 变化
  useEffect(() => {
    tonConnectUI.onStatusChange((wallet) => {
      try {
        if (wallet?.account && wallet?.connectItems) {
          setTonWallet(wallet);
        }
        if (!wallet) {
          localStorage.removeItem("token");
          setTonWallet(null);
        }
      } catch (e) {
        return null;
      }
    });
  }, []);
  const params: EnvContextParams = {
    isSandbox: window.location.search.includes("sandbox"),
    network,
    openTonConnect,
    setNetwork,
    clear,
    isConnect,
    setIsConnect,
  };
  return (
    <div style={{ position: "relative" }}>
      <img
        src={BackLeft}
        style={{ width: "20%", position: "absolute", top: "0", left: "0" }}
        alt=""
      />
      <img
        src={BackRight}
        style={{ width: "20%", position: "absolute", bottom: "0", right: "0" }}
        alt=""
      />
      <div className="dusty">
        <div className="dusty-box">
          <div className="dusty-box-po">
            <p className="comming">Comming soon</p>
            <p className="back" onClick={() => window.open("https://app.dexpert.io/dapps/swap")}>
              Back
            </p>
          </div>
        </div>
      </div>
      {/* backLogo.svg */}
      <AppWrapper>
        <EnvContext.Provider value={params}>
          <ScreensWrapper>
            <Routes>
              <Route
                path="*"
                element={
                  <>
                    <Header />
                    <Navigate to="/" />
                    <PageNotFound />
                  </>
                }
              />
              <Route path="/" element={<Header />}>
                <Route path="/" element={<ContentWrapper />}>
                  <Route path={ROUTES.home} element={<Home />} />
                  <Route path={ROUTES.deployer} element={<DeployerPage />} />
                  <Route path={ROUTES.token} element={<TokenList />} />
                  <Route path={ROUTES.jettonId} element={<Jetton />} />
                  <Route path={ROUTES.jettonIdPool} element={<Pool />} />
                </Route>
              </Route>
            </Routes>
          </ScreensWrapper>
        </EnvContext.Provider>
      </AppWrapper>
    </div>
  );
};

export default App;

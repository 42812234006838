import { AppButton } from "components/appButton";
import { useContext } from "react";
import { EnvContextParams, EnvContext } from "App";
function ConnectAction() {
  const { openTonConnect }: EnvContextParams = useContext(EnvContext);
  return (
    <AppButton onClick={openTonConnect} height={"40px"}>
      <span style={{ fontSize: "18px" }}>Connect wallet</span>
    </AppButton>
  );
}
export default ConnectAction;

import "./header.scss";
import { IconButton, Modal, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useContext, useEffect, useState } from "react";
// import { HeaderMenu } from "components/header/headerMenu/HeaderMenu";
import { AppLogo } from "components/appLogo";
import { SearchBar } from "components/header/headerSearchBar";
import { Collapse, Dropdown } from "antd";
import Language from "assets/icons/lange.svg";
import ActiveLogo from "assets/icons/activeLogo.svg";
import LoadImg from "components/allLoad/loadImg";
import DexpertLogo from "assets/icons/dexpertLogo.svg";
import DrowBot from "assets/icons/drowBot.svg";
import { useTonAddress } from "@tonconnect/ui-react";
import CopyToClipboard from "react-copy-to-clipboard";
import type { CollapseProps } from "antd";
import { HeaderContent, HeaderOptionalContent, HeaderWrapper } from "./styled";
import { EnvContextParams, EnvContext } from "App";
import { Outlet } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
export const Header = () => {
  const { network, setNetwork, openTonConnect, clear, isConnect, setIsConnect }: EnvContextParams =
    useContext(EnvContext);
  const { t, i18n } = useTranslation();
  const [, setMobileMenu] = useState(false);
  const userFriendlyAddress = useTonAddress();
  const [mobileSearchModal, setMobileSearchModal] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [example, setExample] = useState<string | undefined>(undefined);
  const [active, setActive] = useState<any>([]);
  const [isClickWall, setIsClickWall] = useState(false);
  const i18 = localStorage.getItem("i18nextLng");
  //   copy
  const [isCopy, setIsCopy] = useState<any>(false);
  const resetExample = useCallback(() => {
    setExample(undefined);
  }, []);
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: network === "testnet" ? t("page.Testnet") : t("page.Mainnet"),
      children: (
        <div className="item">
          {[
            { name: t("page.Mainnet"), key: "mainnet" },
            { name: t("page.Testnet"), key: "testnet" },
          ].map((i) => {
            return (
              <p
                style={{ color: network === i.key ? "rgb(134,240,151)" : "white" }}
                key={i.key}
                onClick={() => {
                  setNetwork(i.key);
                  setActive([]);
                }}>
                {i.name}
              </p>
            );
          })}
        </div>
      ),
    },
  ];
  const onChange = (key: string | string[]) => {
    setActive(key);
  };
  const DropdownItems: any = [
    {
      key: "1",
      label: (
        <span
          style={{ color: i18 === "zh_CN" ? "#86f097" : "white" }}
          onClick={() => {
            i18n.changeLanguage("zh_CN");
          }}>
          中文
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span
          style={{ color: i18 !== "zh_CN" ? "#86f097" : "white" }}
          onClick={() => {
            i18n.changeLanguage("en_US");
          }}>
          English
        </span>
      ),
    },
  ];
  useEffect(() => {
    if (!userFriendlyAddress) {
      setIsClickWall(false);
    }
  }, [userFriendlyAddress]);
  useEffect(() => {
    if (isCopy) {
      let time: any = setInterval(() => {
        setIsCopy(false);
        clearInterval(time);
      }, 2000);
    }
  }, [isCopy]);

  return (
    <>
      <HeaderWrapper position="static">
        <HeaderContent>
          <HeaderOptionalContent>
            <AppLogo mobile={!matches} />
            <div className="headerBoxRight">
              <div className={`CollapseBox ${active.length === 1 ? "CollapseBoxActive" : ""}`}>
                <Collapse items={items} onChange={onChange} activeKey={active} />
              </div>
              <div className="connectBox">
                <div
                  className={`connectMore ${userFriendlyAddress ? "connectHover" : ""} ${
                    isClickWall ? "connectAc" : ""
                  }`}
                  onClick={() => {
                    if (!userFriendlyAddress) {
                      setIsConnect(true);
                      openTonConnect();
                    } else {
                      if (!isConnect) {
                        setIsClickWall(!isClickWall);
                      }
                    }
                  }}>
                  {/* loading */}
                  {isConnect ? (
                    <LoadImg />
                  ) : (
                    <img src={userFriendlyAddress ? ActiveLogo : DexpertLogo} alt="" />
                  )}
                  <span>
                    {userFriendlyAddress && !isConnect
                      ? userFriendlyAddress.slice(0, 5) + "...." + userFriendlyAddress.slice(-5)
                      : t("page.ConnectWallet")}
                  </span>
                  {userFriendlyAddress && !isConnect && (
                    <img
                      src={DrowBot}
                      alt=""
                      style={{
                        transition: "all 0.6s",
                        transform: isClickWall === true ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                    />
                  )}
                </div>
                {userFriendlyAddress && !isConnect && (
                  <div className={`drop ${isClickWall === true ? "dropClick" : ""}`}>
                    {isCopy ? (
                      <p>{t("page.Copied")}</p>
                    ) : (
                      <CopyToClipboard text={userFriendlyAddress}>
                        <p onClick={() => setIsCopy(true)}>{t("page.Copy")}</p>
                      </CopyToClipboard>
                    )}
                    <p
                      onClick={() => {
                        clear("have");
                      }}>
                      {t("page.logout")}
                    </p>
                  </div>
                )}
              </div>
              <Dropdown
                overlayClassName={"DropdownName"}
                placement="bottom"
                menu={{ items: DropdownItems }}>
                <img src={Language} alt="" style={{ width: "30px" }} />
              </Dropdown>
            </div>
          </HeaderOptionalContent>
          {matches ? (
            <SearchBar
              example={example}
              resetExample={resetExample}
              closeMenu={() => setMobileMenu(false)}
            />
          ) : (
            <IconButton style={{}} onClick={() => setMobileSearchModal(true)}>
              <SearchIcon style={{ width: 32, height: 32, color: "#50A7EA" }} />
            </IconButton>
          )}
        </HeaderContent>
      </HeaderWrapper>
      <Outlet />
      <Modal
        open={mobileSearchModal}
        onClose={(e) => {
          setMobileSearchModal(false);
        }}>
        <Box sx={{ width: "90%", margin: "50% auto" }}>
          <SearchBar
            example={example}
            resetExample={resetExample}
            closeMenu={() => setMobileMenu(false)}
          />
        </Box>
      </Modal>
    </>
  );
};

import { useState } from "react";
import {
  StyledBlock,
  StyledCategoryFields,
  StyledTop,
  StyledTopImg,
  StyledTopText,
} from "pages/jetton/styled";
import LoadingImage from "components/LoadingImage";
import LoadingContainer from "components/LoadingContainer";
import { Box, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  adminActions,
  getAdminMessage,
  getFaultyMetadataWarning,
  getMetadataWarning,
  getTotalSupplyWarning,
  totalSupplyActions,
} from "pages/jetton/util";
import { DataRow } from "pages/jetton/dataRow/DataRow";
import BigNumberDisplay from "components/BigNumberDisplay";
import UpdateMetadata from "pages/jetton/actions/UpdateMetadata";
import useJettonStore from "store/jetton-store/useJettonStore";
import { AppHeading } from "components/appHeading";
import brokenImage from "assets/icons/dexpertTokenLogo.svg";
import { AppButton } from "components/appButton";
import { useTranslation } from "react-i18next";
import pen from "assets/icons/update.svg";
import { CenteringWrapper } from "components/footer/styled";

export const Token = () => {
  const {
    jettonImage,
    adminAddress,
    isAdmin,
    adminRevokedOwnership,
    symbol,
    name,
    description,
    jettonMaster,
    persistenceType,
    totalSupply,
    jettonWalletAddress,
    isJettonDeployerFaultyOnChainData,
    jettonLoading,
    decimals,
    isImageBroken,
  } = useJettonStore();
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  // text: `${t('page.administrator')}[${t('page.Readmore')}](${commonGithubUrl})`,
  return (
    <StyledBlock sx={{ width: "calc(55% - 15px)" }}>
      {!openEdit ? (
        <>
          <StyledTop>
            <StyledTopImg>
              <LoadingImage
                src={!isImageBroken ? jettonImage : brokenImage}
                alt="jetton image"
                loading={jettonLoading}
              />
            </StyledTopImg>
            <StyledTopText marginLeft="4px" marginTop="3px">
              <LoadingContainer loading={jettonLoading} loaderWidth="80px">
                {name && (
                  <AppHeading
                    text={`${name} ${symbol && `(${symbol})`}`}
                    variant="h2"
                    fontWeight={800}
                    fontSize={20}
                    color="#fff"
                  />
                )}
              </LoadingContainer>
              <LoadingContainer loading={jettonLoading} loaderWidth="150px">
                <Tooltip arrow title={description && description?.length > 80 ? description : ""}>
                  <Box marginTop=".5px" sx={{ maxWidth: 300, maxHeight: 60 }}>
                    <AppHeading
                      text={description || "Description"}
                      limitText={80}
                      variant="h4"
                      fontWeight={500}
                      fontSize={16}
                      color="rgba(255, 255, 255, 0.85);"
                    />
                  </Box>
                </Tooltip>
              </LoadingContainer>
            </StyledTopText>
            {isAdmin && !adminRevokedOwnership && !jettonLoading && (
              <Box sx={{ alignSelf: "start" }}>
                <AppButton
                  width={113}
                  height={32}
                  transparent={"true"}
                  onClick={() => setOpenEdit(true)}>
                  <CenteringWrapper>
                    {t("page.EditToken")}
                    <img
                      src={pen}
                      alt="Pen Icon"
                      width={14}
                      height={14}
                      style={{ marginLeft: 6 }}
                    />
                  </CenteringWrapper>
                </AppButton>
              </Box>
            )}
          </StyledTop>
          {!isAdmin && isJettonDeployerFaultyOnChainData && (
            <Alert variant="filled" severity="error">
              {getFaultyMetadataWarning(adminRevokedOwnership)}
            </Alert>
          )}
          <StyledCategoryFields>
            <DataRow
              description={t("page.tokenAddress")}
              title={t("page.Address")}
              value={jettonMaster}
              dataLoading={jettonLoading}
              address={jettonMaster}
            />
            <DataRow
              title={t("page.Admin")}
              value={adminRevokedOwnership ? "Empty address" : adminAddress}
              address={adminAddress}
              description={t("page.Account")}
              message={getAdminMessage(
                jettonWalletAddress,
                symbol,
                adminRevokedOwnership,
                isAdmin,
                jettonMaster,
                { one: t("page.revoke"), two: t("page.Readmore") },
              )}
              dataLoading={jettonLoading}
              actions={adminActions}
              hasButton={(isAdmin && !adminRevokedOwnership) + ""}
              showIcon={!isAdmin}
              regularAddress
            />
            <DataRow
              title={t("page.Symbol")}
              value={symbol}
              dataLoading={jettonLoading}
              message={getMetadataWarning(persistenceType, adminRevokedOwnership, {
                one: t("page.changed"),
                two: t("page.Readmore"),
              })}
            />
            <DataRow
              title={t("page.Decimals")}
              value={decimals}
              dataLoading={jettonLoading}
              message={getMetadataWarning(persistenceType, adminRevokedOwnership, {
                one: t("page.changed"),
                two: t("page.Readmore"),
              })}
            />
            <DataRow
              title={t("page.Total")}
              value={
                totalSupply && (
                  <>
                    <BigNumberDisplay
                      value={totalSupply.toString()}
                      decimals={parseInt(decimals!)}
                    />
                    <span style={{ marginLeft: "8px" }}>({symbol})</span>
                  </>
                )
              }
              dataLoading={jettonLoading}
              message={getTotalSupplyWarning(persistenceType, adminRevokedOwnership, {
                one: t("page.adminMint"),
                two: t("page.Readmore"),
              })}
              actions={totalSupplyActions}
            />
          </StyledCategoryFields>
        </>
      ) : (
        <UpdateMetadata setOpen={setOpenEdit} />
      )}
    </StyledBlock>
  );
};

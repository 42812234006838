import "./index.scss";
import { useNavigate } from "react-router-dom";
import CreateToken from "assets/icons/createToken.svg";
import CreateTokenAc from "assets/icons/createTokenAc.svg";
import YouTokenAc from "assets/icons/youTokenAc.svg";
import YouToken from "assets/icons/youToken.svg";
import CreatePoolAc from "assets/icons/createPoolAc.svg";
import { useTonAddress } from "@tonconnect/ui-react";
import CreatePool from "assets/icons/createPool.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mnemonicNew, mnemonicToPrivateKey } from "@ton/crypto";
function Home() {
  const history = useNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const item = (img: string, name: string) => {
    return (
      <div
        className="item"
        onMouseOver={() => {
          setStatus(name);
        }}
        onClick={() => {
          if (name === "CreateToken") {
            history("/deployer");
          } else if (name === "YouTokenAc") {
            history("/token");
          } else if (name === "CreatePoolAc") {
            history("/jetton/1/pool");
          }
        }}
        onMouseOut={() => {
          setStatus("");
        }}>
        <img src={img} alt="" />
        <p>
          {name === "CreateToken"
            ? t("page.Create")
            : name === "YouTokenAc"
            ? t("page.YourToken")
            : t("page.CreateLiquidity")}
        </p>
      </div>
    );
  };
  return (
    <div className="newIndexBox">
      <div className="box" style={{ display: "none" }}>
        <div className="left" onClick={() => history("/deployer")}>
          <span>铸造你的代币</span>
        </div>
        <div className="right">
          <div onClick={() => history("/token")}>
            <span>你的代币</span>
          </div>
          <div onClick={() => history("/jetton/1/pool")}>
            <span>创建池子</span>
          </div>
        </div>
      </div>
      <div className="imgBox">
        {item(status === "CreateToken" ? CreateTokenAc : CreateToken, "CreateToken")}
        <div className="right">
          {item(status === "YouTokenAc" ? YouTokenAc : YouToken, "YouTokenAc")}
          {item(status === "CreatePoolAc" ? CreatePoolAc : CreatePool, "CreatePoolAc")}
        </div>
      </div>
      <div className="botBox">
        <p
          className="bot"
          onClick={() => {
            window.open("https://blog.ton.org/an-introduction-to-jetton-minting-on-ton");
          }}>
          {t("page.what")}
        </p>
      </div>
    </div>
  );
}

export { Home };

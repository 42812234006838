import { Typography } from "@mui/material";
import { Popup } from "components/Popup";
import useNotification from "hooks/useNotification";
import { poolDeployController } from "lib/deploy-controller";
import { useState, useContext } from "react";
import useJettonStore from "store/jetton-store/useJettonStore";
import { AppButton } from "components/appButton";
import { AppNumberInput } from "components/appInput";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { toNano } from "ton";
import { networkConfig } from "constan";
import { EnvContextParams, EnvContext } from "App";

function ProvideLiquidityAction() {
  const [amountA, setAmountA] = useState<number | undefined>(undefined);
  const [amountB, setAmountB] = useState<number | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const {
    jettonMaster,
    symbol,
    getJettonDetails,
    balance,
    jettonWalletAddress,
    isMyWallet,
    decimals,
  } = useJettonStore();
  const { showNotification } = useNotification();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [tonconnect] = useTonConnectUI();
  const walletAddress = useTonAddress();
  const { network }: EnvContextParams = useContext(EnvContext);
  if (!balance || !isMyWallet) {
    return null;
  }
  const onProvideLiquidity = async () => {
    if (!jettonMaster) {
      return;
    }
    try {
      setActionInProgress(true);
      const result = await poolDeployController.provideLiquidity(
        tonconnect,
        jettonMaster,
        networkConfig[network].ptonAddress,
        toNano(amountA as number).toString(),
        toNano(amountB as number).toString(),
        networkConfig[network].routerAddress,
        walletAddress,
        networkConfig[network].ptonAddress,
        network,
      );
      const message = `Successfully create pool`;
      showNotification(message, "success");
      getJettonDetails(network);
    } catch (error) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
      }
    } finally {
      setActionInProgress(false);
      setOpen(false);
    }
  };

  const onClose = () => {
    setAmountA(0);
    setAmountB(0);
    setOpen(false);
  };

  return (
    <>
      <Popup open={open && !actionInProgress} onClose={onClose} maxWidth={400}>
        <>
          <Typography className="title">Provide Liquidity</Typography>
          <AppNumberInput
            label={`Enter ${symbol} amount`}
            value={amountA}
            onChange={(value: number) => setAmountA(value)}
          />
          <AppNumberInput
            label={`Enter ${symbol} amount`}
            value={amountB}
            onChange={(value: number) => setAmountB(value)}
          />
          <AppButton onClick={onProvideLiquidity}>Submit</AppButton>
        </>
      </Popup>
      <AppButton loading={actionInProgress} transparent={"true"} onClick={() => setOpen(true)}>
        Provide Liquidity
      </AppButton>
    </>
  );
}

export default ProvideLiquidityAction;

import { useEffect, useContext, useState } from "react";
import Back from "assets/icons/backRouter.svg";
import axios from "axios";
import "./token.scss";
import { Address } from "ton";
import { EnvContextParams, EnvContext } from "App";
import { networkConfig } from "constan";
import { useTonAddress } from "@tonconnect/ui-react";
import None from "assets/icons/noData.svg";
import RightGo from "assets/icons/rightGo.svg";
import Loading from "components/allLoad/loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TokenList() {
  const history = useNavigate();
  const { t } = useTranslation();
  const { network }: EnvContextParams = useContext(EnvContext);
  const walletAddress = useTonAddress();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const getToken = async () => {
    try {
      const data = await axios({
        method: "get",
        url: networkConfig[network].url + "/v2/accounts/" + walletAddress + "/jettons",
        params: {
          currencies: ["ton"],
        },
      });
      if (data?.status === 200) {
        setData(data?.data?.balances);
        setLoad(true);
      } else {
        setLoad(true);
      }
    } catch (e) {
      setLoad(true);
      return null;
    }
  };
  useEffect(() => {
    if (walletAddress) {
      getToken();
    }
  }, [walletAddress]);

  return (
    <>
      <div className="manage">
        <p className="manageBack">
          <img src={Back} alt="" onClick={() => history("/")} />
          <span>{t("page.Manage")}</span>
        </p>
        {load ? (
          <>
            {data.length > 0 ? (
              data.map((i, key) => {
                const address = Address.parse(i?.jetton?.address).toFriendly();
                return (
                  <div key={key} className="item" onClick={() => history("/jetton/" + address)}>
                    <div>
                      {i?.jetton?.image ? (
                        <img src={i?.jetton?.image} alt="" />
                      ) : (
                        <p className="img">
                          <span>{i?.jetton?.name?.slice(0, 1)}</span>
                        </p>
                      )}
                      <div>
                        <p>
                          {i?.jetton?.name} ({i?.jetton?.symbol})
                        </p>
                        <p>{address}</p>
                      </div>
                    </div>
                    <img src={RightGo} width={"14px"} alt="" />
                  </div>
                );
              })
            ) : (
              <div className="nodata">
                <img
                  src={None}
                  style={{ width: "15%", minWidth: "100px", margin: "0 auto 20px" }}
                  alt=""
                />
                <div className="bot">
                  <span>您还没有属于您的代币，</span>
                  <span onClick={() => history("/deployer")}>现在去创建吧→</span>
                </div>
              </div>
            )}
          </>
        ) : (
          <Loading status="20" />
        )}
      </div>
    </>
  );
}
export { TokenList };

import None from "assets/icons/noData.svg";
import { useTranslation } from "react-i18next";

function Nodata({ name }: any) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <img src={None} style={{ width: "15%", minWidth: "100px" }} alt="" />
      <p
        style={{
          fontSize: "20px",
          color: "rgb(220,220,220)",
          paddingTop: "15px",
        }}>
        {name ? name : t("page.no")}
      </p>
    </div>
  );
}

export default Nodata;

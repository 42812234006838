import Add from "assets/icons/addToken.svg";
import Loading from "components/allLoad/loading";
import { Modal } from "antd";
import { networkConfig } from "constan";
import { useParams } from "react-router-dom";
import axios from "axios";
import { EnvContextParams, EnvContext } from "App";
import "../index.scss";
import { useState, useContext, useEffect } from "react";
import { useTonAddress } from "@tonconnect/ui-react";
import Request from "components/Axios";
import { Address } from "ton";
import { getClientNow } from "lib/get-ton-client";
import { DEX, pTON } from "@ston-fi/sdk";
import { Address as Address1 } from "@ton/ton";
import TonLogo from "assets/icons/ton_symbol.svg";
import ModalToken from "./modal";
import ItemToken from "./itemToken";
import Decimal from "decimal.js";
import LoadImg from "components/allLoad/loadImg";
import { useTranslation } from "react-i18next";
import { throttle } from "radash";
Decimal.set({ toExpPos: 24, precision: 24 });
export default function SelectToken({
  style,
  sure,
  isSubmit,
  hidden,
  getTokenAddLiquidity,
  refresh,
}: any) {
  const { network, clear }: EnvContextParams = useContext(EnvContext);
  const { t } = useTranslation();
  const { allAxios } = Request();
  const [load, setLoad] = useState(false);
  const walletAddress = useTonAddress();
  //   所有拥有的token
  const [allToken, setAllToken] = useState([]);
  const [poolAllToken, setPoolAllToken] = useState(null);
  // router  token  是否存在
  const [isShow, setIsShow] = useState(false);
  // 打开弹窗
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatus, setIisStatus] = useState("1");
  const params = useParams();
  const getToken = async () => {
    try {
      const paramsJetton = await Promise.all([
        axios({
          method: "get",
          url: networkConfig[network].url + "/v2/accounts/" + walletAddress,
        }),
        axios({
          method: "get",
          url: networkConfig[network].url + "/v2/accounts/" + walletAddress + "/jettons",
          params: { currencies: "usd", supported_extensions: "custom_payload" },
          data: { currencies: "usd", supported_extensions: "custom_payload" },
        }),
      ]);
      if (paramsJetton?.length === 2) {
        const jetton = paramsJetton?.[1];
        const ton = paramsJetton?.[0];
        if (jetton?.status === 200 && jetton?.data?.balances?.length > 0 && ton?.status === 200) {
          const tonPar = [
            {
              balance: ton?.data?.balance,
              jetton: {
                address: networkConfig[network].ptonAddress,
                decimals: 9,
                image: TonLogo,
                name: "Toncoin",
                symbol: "TON",
                wallet_address: {
                  address: walletAddress,
                },
              },
            },
          ];
          const allToken = tonPar.concat(jetton?.data?.balances);
          const allBalance = allToken?.map((i) => {
            const balance = new Decimal(i?.balance).div(new Decimal(10).pow(i?.jetton?.decimals));
            i.balance = balance.toString();
            return i;
          });
          setAllToken(allBalance);
          // 判断是  添加流动性   还是创建pool
          if (params?.pool === "addLiquidity") {
            const list: any = await allAxios({
              method: "get",
              url: "/api/v1/ton/pool/list",
              params: {
                ownerAddress: Address.parse(walletAddress).toFriendly(),
                network,
                page: "1",
                size: "10",
                search: params?.id,
              },
              network,
            });
            if (list === "clear" || list?.response?.data?.msg === "invalid signature") {
              clear("hace");
            } else if (list?.status === 200 && list?.data?.pools?.length > 0) {
              //    过滤   router是否token
              const filter1 = jetton?.data?.balances.filter(
                (i) =>
                  Address.parse(i?.jetton?.address).toFriendly() ===
                  list?.data?.pools?.[0]?.token0Address,
              );
              const filter2 = jetton?.data?.balances.filter(
                (i) =>
                  Address.parse(i?.jetton?.address).toFriendly() ===
                  list?.data?.pools?.[0]?.token1Address,
              );
              if (filter1?.length > 0 && filter2?.length > 0) {
                const client = await getClientNow(network);
                const router = client.open(
                  new DEX.v1.Router(Address1.parse(networkConfig[network].routerAddress)),
                );
                const pool = await client.open(
                  await router.getPool({
                    token0: list?.data?.pools?.[0]?.token0Address,
                    token1: list?.data?.pools?.[0]?.token1Address,
                  }),
                );
                const tt = await pool?.getPoolData();
                getTokenAddLiquidity(
                  [
                    {
                      name: t("page.Address"),
                      key: "address",
                      data: [
                        { name: "Pair", address: list?.data?.pools?.[0]?.poolAddress },
                        {
                          name: filter1[0]?.jetton?.symbol,
                          address: list?.data?.pools?.[0]?.token0Address,
                        },
                        {
                          name: filter2[0]?.jetton?.symbol,
                          address: list?.data?.pools?.[0]?.token1Address,
                        },
                      ],
                    },
                    {
                      name: t("page.Pool"),
                      key: "balance",
                      data: [
                        { name: filter1[0]?.jetton?.symbol, address: Number(tt?.reserve0) / 1e9 },
                        { name: filter2[0]?.jetton?.symbol, address: Number(tt?.reserve1) / 1e9 },
                      ],
                    },
                  ],
                  {
                    name1: filter1[0]?.jetton?.symbol,
                    name2: filter2[0]?.jetton?.symbol,
                  },
                );
                setPoolAllToken({
                  tokenA: { ...filter1[0], value: "" },
                  tokenB: { ...filter2[0], value: "" },
                });
                setLoad(true);
              }
            }
          } else if (params?.pool === "addPool") {
            //    过滤   router是否token
            const filter = jetton?.data?.balances.filter(
              (i) => Address.parse(i?.jetton?.address).toFriendly() === params?.id,
            );
            if (filter.length > 0) {
              setIsShow(true);
              const data =
                jetton?.data?.balances?.[0]?.jetton?.address !== filter[0]?.jetton?.address
                  ? jetton?.data?.balances?.[0]
                  : jetton?.data?.balances?.[1];
              setPoolAllToken({
                tokenA: { ...filter[0], value: "" },
                tokenB: { ...data, value: "" },
              });
              setLoad(true);
            } else {
              setIsShow(false);
              setPoolAllToken({
                tokenA: { ...jetton?.data?.balances?.[0], value: "" },
                tokenB: { ...jetton?.data?.balances?.[1], value: "" },
              });
              setLoad(true);
            }
          }
        } else {
          // 暂无token
          setLoad(true);
        }
      }
    } catch (e) {
      setLoad(true);
      return null;
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const modalParams = { handleCancel, allToken, poolAllToken, isStatus, setPoolAllToken };
  useEffect(() => {
    if (walletAddress && params?.id) {
      getToken();
    }
  }, [walletAddress, params?.id]);

  useEffect(() => {
    if (refresh) {
      getToken();
    }
  }, [refresh]);

  const changeToken = (i: any) => {
    const data =
      poolAllToken?.tokenA?.jetton === i?.jetton || poolAllToken?.tokenB?.jetton === i?.jetton;
    return data;
  };
  const submit = throttle({ interval: 3000 }, async () => {
    if (!isSubmit) {
      if (Number(poolAllToken?.tokenA?.value) && Number(poolAllToken?.tokenB?.value)) {
        await sure(poolAllToken, isShow);
      }
    }
  });
  const changeValue = (name: string, value: string) => {
    setPoolAllToken({
      tokenA: name === "1" ? { ...poolAllToken?.tokenA, value } : poolAllToken?.tokenA,
      tokenB: name === "2" ? { ...poolAllToken?.tokenB, value } : poolAllToken?.tokenB,
    });
  };
  const item = (name: string, data: any) => {
    const tokenParams = {
      data,
      name,
      hidden,
      isShow,
      setIisStatus,
      setIsModalOpen,
      changeValue,
      isSubmit,
    };
    return <ItemToken {...tokenParams} />;
  };

  return (
    <>
      {load ? (
        <div className="SelectTokenBox" style={style}>
          {item("1", poolAllToken?.tokenA)}
          <img src={Add} alt="" className="jia" />
          {item("2", poolAllToken?.tokenB)}
          <p className="sure" onClick={submit}>
            {isSubmit ? <LoadImg /> : t("page.Confirm")}
          </p>
          <Modal
            title=""
            centered
            open={isModalOpen}
            className="selectTokenModal"
            closable={false}
            maskClosable={false}
            footer={null}
            onCancel={handleCancel}>
            <ModalToken {...modalParams} />
          </Modal>
        </div>
      ) : (
        <Loading status="20" />
      )}
    </>
  );
}

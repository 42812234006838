import useNotification from "hooks/useNotification";
import { jettonDeployController } from "lib/deploy-controller";
import { useState, useContext } from "react";
import useJettonStore from "store/jetton-store/useJettonStore";
import { AppButton } from "components/appButton";
import { validateTransfer } from "./utils";
import { ButtonWrapper, TransferContent, TransferWrapper } from "./styled";
import { AppHeading } from "components/appHeading";
import { AppNumberInput, AppTextInput } from "components/appInput";
import { toDecimalsBN } from "utils";
import { useRecoilState, useSetRecoilState } from "recoil";
import { jettonActionsState } from "pages/jetton/actions/jettonActions";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { EnvContextParams, EnvContext } from "App";
import { useTranslation } from "react-i18next";

export const TransferAction = () => {
  const { network }: EnvContextParams = useContext(EnvContext);
  const { balance, symbol, jettonWalletAddress, getJettonDetails, isMyWallet, decimals } =
    useJettonStore();
  const { t } = useTranslation();

  const [toAddress, setToAddress] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const { showNotification } = useNotification();
  const connectedWalletAddress = useTonAddress();
  const [tonconnect] = useTonConnectUI();
  const [actionInProgress, setActionInProgress] = useRecoilState(jettonActionsState);

  if (!balance || !jettonWalletAddress || !isMyWallet) {
    return null;
  }

  const onSubmit = async () => {
    const error = validateTransfer(
      toAddress,
      toDecimalsBN(amount!, decimals!),
      balance,
      symbol,
      decimals,
    );
    if (error) {
      showNotification(error, "warning", undefined, 3000);
      return;
    }

    setActionInProgress(true);
    try {
      await jettonDeployController.transfer(
        tonconnect,
        toDecimalsBN(amount!.toString(), decimals!),
        toAddress!,
        connectedWalletAddress!,
        jettonWalletAddress,
        network,
      );
      setToAddress(undefined);
      setAmount(undefined);
      getJettonDetails(network);
      showNotification(
        `${t("page.Successfullytransfered")} ${amount?.toLocaleString()} ${symbol}`,
        "warning",
        undefined,
        4000,
      );
    } catch (error) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
      }
    } finally {
      setActionInProgress(false);
    }
  };

  return (
    <TransferWrapper>
      <AppHeading
        text={`${t("page.Transfer")} ${symbol}`}
        variant="h4"
        fontWeight={800}
        fontSize={20}
        marginBottom={20}
        color="#fff"
      />
      <TransferContent>
        <p
          style={{
            color: "rgba(255, 255, 255, 0.75)",
            fontSize: "20px",
            fontWeight: "400",
            marginBottom: "6px",
          }}>
          {t("page.Receiving")}
        </p>
        <AppTextInput
          fullWidth
          label=""
          value={toAddress}
          onChange={(e) => setToAddress(e.target.value)}
        />
        <p
          style={{
            color: "rgba(255, 255, 255, 0.75)",
            fontSize: "20px",
            fontWeight: "400",
            marginBottom: "6px",
            marginTop: "20px",
          }}>
          {t("page.TransferAmount")}
        </p>
        <AppNumberInput label="" onChange={(value: number) => setAmount(value)} value={amount} />
      </TransferContent>
      <ButtonWrapper>
        <AppButton
          disabled={!(toAddress && amount)}
          onClick={onSubmit}
          height={50}
          loading={actionInProgress}>
          {t("page.Transfer")}
        </AppButton>
      </ButtonWrapper>
    </TransferWrapper>
  );
};

import { useTonAddress } from "@tonconnect/ui-react";
import { Screen, ScreenContent } from "components/Screen";
import { Token } from "pages/jetton/dataRow/token";
import { StyledContainer } from "pages/jetton/styled";
import { Wallet } from "pages/jetton/wallet";
import { useEffect, useState, useContext } from "react";
import useJettonStore from "store/jetton-store/useJettonStore";
import { EnvContextParams, EnvContext } from "App";
import useNotification from "hooks/useNotification";
import FaultyDeploy from "./FaultyDeploy";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Back from "assets/icons/backRouter.svg";
import Loading from "components/allLoad/loading";
export const Jetton = () => {
  const { network }: EnvContextParams = useContext(EnvContext);
  const history = useNavigate();
  const { getJettonDetails, symbol, name } = useJettonStore();
  const params = useParams();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const address = useTonAddress();
  const [load, setLoad] = useState(false);
  const getDetail = async () => {
    const data = await getJettonDetails(network);
    if (data === "Invalid address") {
      showNotification("Invalid address", "error");
      setLoad(true);
    }
  };
  useEffect(() => {
    if (params?.id && network) {
      getDetail();
    }
  }, [params?.id, address]);
  useEffect(() => {
    if (symbol && name) {
      setLoad(true);
    }
  }, [symbol, name]);
  return (
    <>
      <p className="manageBack">
        <img src={Back} alt="" onClick={() => history("/token")} />
        <span>{t("page.set")}</span>
      </p>
      {load ? (
        <Screen>
          <FaultyDeploy />
          <ScreenContent>
            <StyledContainer>
              <Token />
              <Wallet />
            </StyledContainer>
          </ScreenContent>
        </Screen>
      ) : (
        <Loading status="20" />
      )}
    </>
  );
};

import ShowMore from "assets/icons/showMore.svg";
import { useEffect, useState, useContext } from "react";
import Nodata from "components/Nodata";
import { useTonAddress } from "@tonconnect/ui-react";
import axios from "axios";
import { Address } from "ton";
import { useNavigate, useParams } from "react-router-dom";
import { networkConfig } from "constan";
import { EnvContextParams, EnvContext } from "App";
import Request from "components/Axios";
import Loading from "components/allLoad/loading";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
export default function PairList() {
  const { allAxios } = Request();
  const history = useNavigate();
  const { t } = useTranslation();
  const { network, clear }: EnvContextParams = useContext(EnvContext);
  const walletAddress = useTonAddress();
  const router = useParams();
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const getPoolList = async () => {
    try {
      const params: any = await Promise.all([
        allAxios({
          method: "get",
          url: "/api/v1/ton/pool/list",
          params: {
            page: "1",
            network,
            ownerAddress: Address.parse(walletAddress).toFriendly(),
            search: router?.id !== "1" ? Address.parse(router?.id).toFriendly() : "",
          },
          network,
        }),
        axios({
          method: "get",
          url:
            networkConfig[network].url +
            "/v2/accounts/" +
            Address.parse(walletAddress).toFriendly() +
            "/jettons",
          params: { currencies: "usd", supported_extensions: "custom_payload" },
          data: { currencies: "usd", supported_extensions: "custom_payload" },
        }),
      ]);
      if (params[0] === "clear" || params[0]?.response?.data?.msg === "invalid signature") {
        clear("have");
      } else if (params.length === 2) {
        if (params[0]?.status === 200 && params[1]?.status === 200) {
          if (params[0]?.data?.pools?.length > 0 && params[1]?.data?.balances?.length > 0) {
            const tt = params[0]?.data?.pools.filter((item) => {
              return params[1]?.data?.balances.map((i) => {
                if (Address?.parse(i?.jetton?.address).toFriendly() === item?.token0Address) {
                  item.token0Name = i?.jetton?.name;
                } else if (
                  Address?.parse(i?.jetton?.address).toFriendly() === item?.token1Address
                ) {
                  item.token1Name = i?.jetton?.name;
                }
              });
            });
            setData(tt);
            setLoading(true);
          } else {
            setLoading(true);
          }
        } else {
          setLoading(true);
        }
      } else {
        setLoading(true);
      }
    } catch (e) {
      setLoading(true);
      return null;
    }
  };
  useEffect(() => {
    if (!token) {
      setLoading(true);
    } else if (walletAddress && router?.id) {
      getPoolList();
    }
  }, [walletAddress, router?.id]);
  return (
    <>
      {loading ? (
        data.length > 0 ? (
          data.map((i) => {
            return (
              <div key={i.poolAddress} className="poolBoxToken">
                <div
                  className="poolItem"
                  onClick={() => history("/jetton/" + i?.token0Address + "/addLiquidity")}>
                  <div className="left">
                    <div>
                      <p>
                        <span>{i?.token0Name?.slice(0, 1) || ""}</span>
                      </p>
                      <p>
                        <span>{i?.token1Name?.slice(0, 1) || ""}</span>
                      </p>
                    </div>
                    <div>
                      <span>{i?.token0Name}</span>
                      <span>/</span>
                      <span>{i?.token1Name}</span>
                    </div>
                  </div>
                  <div className="right">
                    <p>
                      <span>{t("page.creation")}:</span>
                      <span>
                        {i?.createdAt
                          ? dayjs.unix(i?.createdAt).format("DD/MM/YYYY")
                          : "00/00/0000"}
                      </span>
                    </p>
                    <img src={ShowMore} alt="" />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Nodata />
        )
      ) : (
        <Loading status={"20"} />
      )}
    </>
  );
}

export const simplify = (data: string) => {
  if (Number(data)) {
    const t = Number(data);
    if (t >= 1e9) {
      return Math.floor(t / 1e9) + " B";
    } else if (t >= 1e6) {
      return Math.floor(t / 1e6) + " M";
    } else if (t >= 1e3) {
      return Math.floor(t / 1e3) + " K";
    } else {
      return t;
    }
    // return data;
  } else {
    if (data?.length > 20) {
      const l = data.slice(0, 5) + "..." + data.slice(-5);
      return l;
    } else {
      return data;
    }
  }
};

export const tokenDecryption = (value: string) => {
  const base64Url = value?.split(".");
  const base641 = base64Url[1].replace(/-/g, "+").replace(/_/g, "/");
  const decodedToken = JSON.parse(atob(base641));
  return decodedToken;
};

import useNotification from "hooks/useNotification";
import { useForm } from "react-hook-form";
import { Box, Tooltip, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { AppButton } from "components/appButton";
import { useParams } from "react-router-dom";
import { CenteringWrapper } from "components/header/headerSearchBar/styled";
import { EditLogoPopup } from "components/editLogoPopup";
import {
  JettonFormTitle,
  StyledActionBtn,
  StyledForm,
  StyledFormInputs,
} from "components/form/styled";
import { useTranslation } from "react-i18next";

import { Input } from "components/form/input";
import { LogoAlertPopup } from "components/logoAlertPopup";
import { useJettonLogo } from "hooks/useJettonLogo";
import coinLogoHover from "assets/icons/coin-logo-hover.svg";
import { StyledTopImg } from "pages/jetton/styled";
import LoadingImage from "components/LoadingImage";
import { AppHeading } from "components/appHeading";
import { useTonAddress } from "@tonconnect/ui-react";
import { EnvContextParams, EnvContext } from "App";

interface FormProps {
  onSubmit: (values: any) => Promise<void>;
  inputs: any[];
  disableExample?: boolean;
  submitText: string;
  defaultValues?: {};
  onCancel?: () => void;
  isLoading?: boolean;
}

export function Form({
  onSubmit,
  inputs,
  disableExample,
  submitText,
  defaultValues,
  onCancel,
  isLoading,
}: FormProps) {
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const { openTonConnect }: EnvContextParams = useContext(EnvContext);
  const address = useTonAddress();
  const { jettonLogo, setIconHover } = useJettonLogo();
  const [logoAlertPopup, setLogoAlertPopup] = useState(false);
  const [editLogoPopup, setEditLogoPopup] = useState(false);
  const params = useParams();
  const matches = useMediaQuery("(max-width:599px)");
  const tokenImage = inputs.filter((i) => i.name === "tokenImage")?.[0];
  const { control, handleSubmit, formState, setValue, clearErrors, watch, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues,
  });
  const errors = formState.errors as any;
  const onFormError = (value: any) => {
    const firstError = value[Object.keys(value)[0]];
    if (!firstError) {
      return;
    }
    showNotification(<>{firstError.message}</>, "warning", undefined, 3000);
  };

  const onExampleClick = useCallback((name: never, value: never) => {
    setValue(name, value);
  }, []);

  const closeEditLogoPopup = useCallback(() => setEditLogoPopup(false), []);

  const closeAlertLogoPopup = useCallback(() => setLogoAlertPopup(false), []);

  const jettonData: any = watch();

  useEffect(() => {
    //@ts-ignore
    setValue("tokenImage", jettonLogo.logoUrl);
  }, [jettonLogo.logoUrl]);
  return (
    <StyledForm
      onSubmit={handleSubmit(() => {
        if (!jettonLogo.logoUrl || jettonLogo.hasError) {
          setLogoAlertPopup(true);
          return;
        }
        onSubmit(getValues());
      }, onFormError)}>
      <EditLogoPopup
        showExample={!params?.id}
        showPopup={editLogoPopup}
        tokenImage={tokenImage}
        close={closeEditLogoPopup}
      />
      <LogoAlertPopup
        isUpdateText={!!params?.id}
        showPopup={logoAlertPopup}
        close={closeAlertLogoPopup}
        onValidate={handleSubmit(onSubmit, onFormError)}
      />
      <Box sx={{ display: "flex" }} mb={3}>
        <CenteringWrapper>
          <StyledTopImg sx={{ position: "relative" }}>
            <img
              alt="Hover icon"
              style={{
                cursor: "pointer",
                position: "absolute",
                left: matches ? -1 : 0,
                top: matches ? -1 : 0,
                opacity: jettonLogo.iconHover ? 0.5 : 0,
                zIndex: 1,
                width: matches ? 60 : 101,
                height: matches ? 60 : 101,
              }}
              onClick={() => setEditLogoPopup(true)}
              onMouseEnter={() => setIconHover(true)}
              onMouseLeave={() => setIconHover(false)}
              src={coinLogoHover}
            />
            <LoadingImage
              src={jettonLogo.image}
              loading={jettonLogo.isLoading}
              alt="jetton image"
            />
          </StyledTopImg>
        </CenteringWrapper>
        <Box ml={3}>
          <JettonFormTitle>
            {jettonData?.name || t("page.Name")} ({jettonData?.symbol || t("page.Symbol")})
          </JettonFormTitle>
          <Tooltip
            arrow
            title={
              jettonData.description && jettonData.description?.length > 80
                ? jettonData.description
                : ""
            }>
            <Box sx={{ maxWidth: 300, maxHeight: 60 }}>
              <AppHeading
                text={jettonData.description || t("page.Description")}
                limitText={80}
                variant="h4"
                fontWeight={500}
                fontSize={16}
                color="rgba(255, 255, 255, 0.85)"
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <StyledFormInputs>
        {inputs
          .filter((i) => i.name !== "tokenImage")
          .filter((i) => !i.disabled)
          .map((spec: any, index: number) => {
            return (
              <Input
                disableExample={disableExample}
                required={spec.required}
                description={spec.description}
                clearErrors={clearErrors}
                key={index}
                error={errors[spec.name]}
                name={spec.name}
                type={spec.type}
                control={control}
                label={spec.label}
                defaultValue={spec.default || ""}
                onExampleClick={() => onExampleClick(spec.name as never, spec.default as never)}
                disabled={spec.disabled}
                errorMessage={spec.errorMessage}
                validate={spec.validate}
                showDefault={spec.showDefault}
              />
            );
          })}
      </StyledFormInputs>
      <StyledActionBtn>
        {!address ? (
          <AppButton
            height={44}
            width={150}
            fontWeight={700}
            type="button"
            onClick={openTonConnect}
            background="rgba(134, 240, 151, 1)">
            {t("page.ConnectWallet")}
          </AppButton>
        ) : (
          <CenteringWrapper sx={{ justifyContent: "center" }}>
            {onCancel && (
              <AppButton
                disabled={jettonLogo.isLoading}
                transparent={"true"}
                onClick={onCancel}
                background="transparent"
                border={"1px solid rgb(134,240,151)"}
                type="button">
                <span style={{ fontSize: "20px" }}>{t("page.Cancel")}</span>
              </AppButton>
            )}
            <AppButton disabled={jettonLogo.isLoading} type="submit" loading={isLoading}>
              <span style={{ fontSize: "20px" }}>{submitText}</span>
            </AppButton>
          </CenteringWrapper>
        )}
      </StyledActionBtn>
    </StyledForm>
  );
}

import { useState, useContext } from "react";
import { Address } from "ton";
import { jettonDeployController, JettonDeployParams } from "lib/deploy-controller";
import WalletConnection from "services/wallet-connection";
import { createDeployParams } from "lib/utils";
import { ContractDeployer } from "lib/contract-deployer";
import { Link as ReactRouterLink } from "react-router-dom";
import { ROUTES } from "consts";
import useNotification from "hooks/useNotification";
import { checkImageURL, checkDecimals } from "helpers";
import { FormWrapper, SubHeadingWrapper } from "./styles";
import analytics, { AnalyticsAction, AnalyticsCategory } from "services/analytics";
import { getUrlParam, toDecimalsBN } from "utils";
import { offchainFormSpec, onchainFormSpec } from "./data";
import { Form } from "components/form";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Back from "assets/icons/backRouter.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { EnvContextParams, EnvContext } from "App";
import { useNavigate } from "react-router-dom";
const DEFAULT_DECIMALS = 9;
const isOffchainInternal = getUrlParam("offchainINTERNAL") !== null;
async function fetchDecimalsOffchain(url: string): Promise<{ decimals?: string }> {
  let res = await fetch(url);
  let obj = await res.json();
  return obj;
}
function DeployerPage() {
  const { network }: EnvContextParams = useContext(EnvContext);
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const offchainFormSpec = [
    {
      name: "offchainUri",
      label: "Offchain URI",
      description: "JSON containing metadata. Don't forget to pin it if it's ipfs",
      type: "string",
      default: "",
      required: true,
      errorMessage: "URI required",
    },
    {
      name: "mintAmount",
      label: "Amount to Mint",
      description: "Number of initial tokens to mint and send to your wallet address (float)",
      type: "number",
      default: 21000000,
      required: true,
      errorMessage: "Mint amount required",
      disabled: undefined,
    },
  ];
  const onchainFormSpec = [
    {
      name: "name",
      label: t("page.Name"),
      description: t("page.NameHint"),
      type: "text",
      default: "Bitcoin Cash",
      required: true,
      errorMessage: t("page.NameRequired"),
    },
    {
      name: "symbol",
      label: t("page.JettonSymbol"),
      description: t("page.SymbolHint"),
      type: "text",
      default: "BCH",
      required: true,
      errorMessage: t("page.NameRequired"),
    },
    {
      name: "decimals",
      label: t("page.Decimals"),
      description: t("page.DecimalsHint"),
      type: "number",
      validate: checkDecimals,
      default: 9,
      showDefault: true,
      required: true,
      errorMessage: t("page.Decimalsrequired"),
    },
    {
      name: "mintAmount",
      label: t("page.Tokens"),
      description: t("page.TokensHint"),
      type: "number",
      default: 21000000,
      required: true,
      errorMessage: t("page.Tokensrequired"),
    },
    {
      name: "description",
      label: t("page.Description"),
      description: t("page.DescriptionHint"),
      type: "string",
      default: "Low fee peer-to-peer electronic cash alternative to Bitcoin",
    },
    {
      name: "tokenImage",
      label: "Jetton Logo URL",
      description: t("page.URL"),
      type: "string",
      required: false,
      validate: checkImageURL,
      default: "https://bitcoincash-example.github.io/website/logo.png",
    },
  ];
  let formSpec = isOffchainInternal ? offchainFormSpec : onchainFormSpec;
  const history = useNavigate();
  const walletAddress = useTonAddress();
  const [tonconnect] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigatePreserveQuery();
  async function deployContract(data: any) {
    try {
      if (!walletAddress || !tonconnect) {
        showNotification(t("page.Wallet"), "error");
        return;
      }
      let decimals = data.decimals;
      if (data.offchainUri) {
        let res = await fetchDecimalsOffchain(
          data.offchainUri.replace("ipfs://", "https://ipfs.io/ipfs/"),
        );
        decimals = res.decimals;
      }
      const params: JettonDeployParams = {
        owner: Address.parse(walletAddress),
        onchainMetaData: {
          name: data.name,
          symbol: data.symbol,
          image: data.tokenImage,
          description: data.description,
          decimals: parseInt(decimals).toFixed(0),
        },
        offchainUri: data.offchainUri,
        amountToMint: toDecimalsBN(data.mintAmount, decimals ?? DEFAULT_DECIMALS),
      };
      setIsLoading(true);
      const deployParams: any = createDeployParams(params, data.offchainUri);
      const contractAddress = new ContractDeployer().addressForContract(deployParams);
      const isDeployed = await WalletConnection.isContractDeployed(contractAddress);
      if (isDeployed) {
        showNotification(
          <>
            {t("page.Contract")}
            <ReactRouterLink
              to={`https://${
                network === "testnet" ? "testnet" : ""
              }tonviewer.com/${Address.normalize(contractAddress)}/`}>
              {t("page.View")}
            </ReactRouterLink>
          </>,
          "warning",
        );
        setIsLoading(false);
        return;
      }
      const result = await jettonDeployController.createJetton(
        params,
        tonconnect,
        walletAddress,
        network,
      );
      analytics.sendEvent(
        AnalyticsCategory.DEPLOYER_PAGE,
        AnalyticsAction.DEPLOY,
        contractAddress.toFriendly(),
      );
      // showNotification(<>Create jetton successful</>, "success");
      navigate(`${ROUTES.jetton}/${Address.normalize(result)}`);
    } catch (err) {
      if (err instanceof Error) {
        showNotification(<>{err.message}</>, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div>
      <div className="back">
        <img src={Back} alt="" onClick={() => history("/")} />
        <span>{t("page.Create")}</span>
      </div>
      <FormWrapper>
        <SubHeadingWrapper>
          <Form
            isLoading={isLoading}
            submitText={t("page.Deploy")}
            onSubmit={deployContract}
            inputs={formSpec}
          />
        </SubHeadingWrapper>
      </FormWrapper>
    </div>
  );
}
export { DeployerPage };

import React, { useEffect } from "react";
import SearchImg from "assets/icons/search.svg";
import { IndentlessIcon, SearchBarInput, SearchBarWrapper } from "./styled";
import close from "assets/icons/close.svg";
import { Backdrop, ClickAwayListener, IconButton } from "@mui/material";
import { AppButton } from "components/appButton";
import { HeaderSearchResults } from "components/header/headerSearchResults";
import { useAddressHistory } from "hooks/useAddressHistory";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  closeMenu?: () => void;
  resetExample?: () => void;
  example?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({ example, resetExample, closeMenu }) => {
  const {
    addresses,
    onAddressClick,
    resetAddresses,
    removeAddress,
    onSubmit,
    setActive,
    setValue,
    addressInput,
  } = useAddressHistory();
  const onAddressRemove = (e: any, address: string) => {
    e.stopPropagation();
    removeAddress(address);
  };
  const { t } = useTranslation();

  useEffect(() => {
    resetExample?.();
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        event.target.blur();
        onSubmit(addressInput.value);
        closeMenu?.();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [addressInput.value, onSubmit]);

  useEffect(() => {
    example && setValue(example);
  }, [example]);

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <div style={{ position: "relative", zIndex: addressInput?.active ? "900" : "90" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: 2, overflow: "hidden" }}
          open={addressInput.active}
          onClick={() => setActive(false)}></Backdrop>
        <SearchBarWrapper
          style={{
            border: addressInput?.active ? "1px solid rgb(134,240,151)" : "1px solid transparent",
          }}>
          <SearchBarInput
            placeholder={t("page.JettonAddress")}
            onPaste={(e: any) => setValue(e.target.value)}
            onChange={(e) => setValue(e.target.value)}
            value={addressInput.value}
            onFocus={() => addresses?.length && setActive(true)}
            spellCheck={false}
          />
          {!!addressInput.value.length && (
            <img
              src={close}
              alt="Close Icon"
              width={18}
              style={{ cursor: "pointer" }}
              height={18}
              onClick={() => setValue("")}
            />
          )}
          <img
            src={SearchImg}
            width={16}
            onClick={() => {
              if (addressInput?.value?.length) {
                onSubmit(addressInput.value);
                closeMenu?.();
              }
            }}
            style={{ cursor: "pointer", marginLeft: "10px" }}
            height={18}
            alt="Search Icon"
          />
          {addressInput.active && !!addresses?.length && (
            <HeaderSearchResults
              searchResults={addresses}
              onHistoryClear={resetAddresses}
              onItemClick={onAddressClick}
              onItemDelete={onAddressRemove}
            />
          )}
        </SearchBarWrapper>
      </div>
    </ClickAwayListener>
  );
};

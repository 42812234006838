import { toNano } from "ton";

type networkConfigType = {
  feeRecipient: string;
  feeAmount: string;
  url: string;
  rpcUrl: string;
  routerAddress: string;
  zeroAddress: string;
  ptonAddress: string;
  scan: string;
};
const networkConfig: { [key: string]: networkConfigType } = {
  testnet: {
    feeRecipient: "0QCZh8IFVKSWXE0RIytwJ1ywy2JTrujkdMPVhos1IIt3o3t0",
    feeAmount: toNano(0.1).toString(),
    url: "https://testnet.tonapi.io",
    rpcUrl: "https://testnet.toncenter.com/api/v2/jsonRPC",
    routerAddress: "EQBsGx9ArADUrREB34W-ghgsCgBShvfUr4Jvlu-0KGc33Rbt",
    zeroAddress: "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c",
    ptonAddress: "EQAcOvXSnnOhCdLYc6up2ECYwtNNTzlmOlidBeCs5cFPVwuG", //wallet: EQCdC2b1GG1saybYxCwRfEqr4WlOexsQIcYcfMYObk_47wBm
    scan: "https://testnet.tonviewer.com/",
  },
  mainnet: {
    feeRecipient: "0QCZh8IFVKSWXE0RIytwJ1ywy2JTrujkdMPVhos1IIt3o3t0",
    feeAmount: toNano(0.1).toString(),
    url: "https://tonapi.io",
    rpcUrl: "https://toncenter.com/api/v2/jsonRPC",
    routerAddress: "EQB3ncyBUTjZUA5EnFKR5_EnOMI9V1tTEAAPaiU71gc4TiUt",
    zeroAddress: "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c",
    ptonAddress: "EQAcOvXSnnOhCdLYc6up2ECYwtNNTzlmOlidBeCs5cFPVwuG",
    scan: "https://tonviewer.com/",
  },
};
export { networkConfig };

import "./index.scss";
import SelectToken from "./selectToken";
import { useContext, useEffect, useState } from "react";
import { networkConfig } from "constan";
import { EnvContextParams, EnvContext } from "App";
import { Tooltip } from "antd";
import { Address, toNano } from "ton";
import useNotification from "hooks/useNotification";
import Loading from "components/allLoad/loading";
import Copy from "assets/icons/copy.svg";
import Refresh from "assets/icons/refreshLogo.svg";
import { simplify } from "utils/change";
import { useTranslation } from "react-i18next";
import { poolDeployController } from "lib/deploy-controller";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import CopyToClipboard from "react-copy-to-clipboard";
import { throttle } from "radash";
export default function AddLiquidity() {
  const [tonconnect] = useTonConnectUI();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const walletAddress = useTonAddress();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [token, setToken] = useState(null);
  const [name, setName] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { network }: EnvContextParams = useContext(EnvContext);
  const submit = async (allToken: any) => {
    setIsSubmit(true);
    try {
      await poolDeployController.provideLiquidity(
        tonconnect,
        Address.parse(allToken?.tokenA?.jetton?.address).toFriendly(),
        Address.parse(allToken?.tokenB?.jetton?.address).toFriendly(),
        toNano(Number(allToken?.tokenA?.value)).toString(),
        toNano(Number(allToken?.tokenB?.value)).toString(),
        networkConfig[network].routerAddress,
        walletAddress,
        networkConfig[network].ptonAddress,
        network,
      );
      const message = `Successfully add liquidity`;
      showNotification(message, "success");
      setIsSubmit(false);
    } catch (e) {
      setIsSubmit(false);
      return null;
    }
  };
  const getTokenAddLiquidity = async (select: any, name: any) => {
    if (select && name) {
      setToken(select);
      setName(name);
      setLoading(true);
      setRefresh(false);
    }
  };
  const [show, setShow] = useState("");
  const balanceRefresh = throttle({ interval: 3000 }, () => {
    if (show === "roImg1") {
      setShow("roImg2");
    } else {
      setShow("roImg1");
    }
    setRefresh(true);
  });
  const changeV = (value: string) => {
    const tt = value?.length > 8 ? simplify(value) : value?.toLocaleString();
    return tt;
  };

  return (
    <>
      <div style={{ display: loading ? "" : "none" }} className="addLiquidityModal">
        <div className="left">
          <div className="title">
            <div className="logo">
              <p>
                <span>{name?.name1?.slice?.(0, 1)}</span>
              </p>
              <p>
                <span>{name?.name2?.slice?.(0, 1)}</span>
              </p>
            </div>
            <div className="name">
              <span>{name?.name1}</span>
              <span>/</span>
              <span>{name?.name2}</span>
            </div>
          </div>
          {token?.length > 0 &&
            token.map((i, ind) => {
              return (
                <div className={`address ${i.key === "address" && "color"}`} key={ind}>
                  <div className="titleRefresh">
                    <p>{i.name}</p>
                    {i.key !== "address" && (
                      <img src={Refresh} alt="" className={show} onClick={balanceRefresh} />
                    )}
                  </div>
                  {i.data?.map((item, index) => {
                    return (
                      <div key={index} className="item">
                        <p>{simplify(item?.name)}</p>
                        <div className="right">
                          <Tooltip
                            title={<span style={{ color: "black" }}>{item?.address}</span>}
                            color={"white"}>
                            <div
                              className="balance"
                              onClick={() => {
                                if (i.key === "address") {
                                  window.open(networkConfig[network]?.scan + item?.address);
                                }
                              }}>
                              {changeV(item?.address)}
                            </div>
                          </Tooltip>
                          {i.key === "address" && (
                            <CopyToClipboard
                              text={item?.address}
                              onCopy={() => {
                                showNotification(t("page.AddressCopied"), "success");
                              }}>
                              <img src={Copy} alt="" />
                            </CopyToClipboard>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
        <SelectToken
          sure={submit}
          hidden={true}
          isSubmit={isSubmit}
          getTokenAddLiquidity={getTokenAddLiquidity}
          refresh={refresh}
        />
      </div>
      {!loading && <Loading status="20" />}
    </>
  );
}

import { Chip, Typography } from "@mui/material";
import { APP_DISPLAY_NAME, ROUTES } from "consts";
import logo from "assets/icons/dexpert-logo.png";
import { LogoWrapper, ImageWrapper } from "./styled";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
export const AppLogo = ({ mobile = false }) => {
  const navigate = useNavigatePreserveQuery();
  return (
    <LogoWrapper>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          position: "relative",
          zIndex: "20",
        }}
        onClick={() => navigate(ROUTES.home)}>
        <ImageWrapper>
          <img src={logo} alt="Logo" />
        </ImageWrapper>
        {!mobile && <Typography variant="h4">{APP_DISPLAY_NAME}</Typography>}
      </div>
    </LogoWrapper>
  );
};

import { Box, styled, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import "./index.scss";
const InputWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .MuiTextField-root": {
    "& .MuiInputLabel-root": {
      color: "#7A828A",
      fontSize: 16,

      "&.Mui-focused": {
        color: "#fff",
      },
    },
    "& input": {
      fontSize: 16,
      color: "#fff",
    },
  },
}));

interface InputProps {
  showlabel?: string;
}

const TextInput = styled(TextField)((props: InputProps) => ({ theme }) => ({
  background: "rgba(72, 72, 72, 1)",
  borderRadius: 40,
  color: "white",
  border: "none",
  paddingLeft: theme.spacing(1),
  "& fieldset": {
    border: "none",
    background: "none",
  },
  "&::placeholder": {
    marginLeft: 15,
    color: "white",
  },
  "& label": {
    display: props?.showlabel === "true" ? "inline" : "none",
    marginLeft: theme.spacing(0.8),
    "&.Mui-focused": {
      display: "none",
    },
  },
}));

interface AppInputProps {
  fullWidth?: boolean;
  label?: string;
  value?: string | number;
  onChange: (e: any) => void;
}

export const AppTextInput: React.FC<AppInputProps> = ({ fullWidth, value, label, onChange }) => {
  return (
    <InputWrapper>
      <TextInput
        fullWidth={fullWidth}
        style={{ color: "white" }}
        label={label}
        value={value}
        onChange={onChange}
        showlabel={!value + ""}
      />
    </InputWrapper>
  );
};

//TODO separate text and number inputs into separate files, refactor AppNumberInput

interface AppNumberInputProps {
  onChange: (val: number) => void;
  value?: number;
  label?: string;
  total?: any;
  status?: any;
}

export const AppNumberInput: React.FC<AppNumberInputProps> = ({
  value,
  label,
  onChange,
  total,
  status,
}) => {
  return (
    <div className="AppNumberInputBox">
      <NumberFormat
        style={{
          background: "rgba(72, 72, 72, 1)",
          color: "#fff",
        }}
        sx={{
          background: "#F7F9FB",
          borderRadius: 40,
          paddingLeft: 1,
          border: "none",
          "& fieldset": {
            border: "none",
            background: "none",
          },
          "& input": {
            padding: "8px 0",
          },
          "& label": {
            display: !value ? "inline" : "none",
            marginLeft: 0.8,
            top: "-8px",
            "&.Mui-focused": {
              display: "none",
            },
          },
        }}
        value={value || ""}
        customInput={TextField}
        thousandSeparator=","
        fullWidth
        autoComplete="off"
        label={label}
        onValueChange={({ value }) => {
          if (Number(value) > Number(total)) {
            onChange(Number(total));
          } else {
            onChange(Number(value));
          }
        }}
      />
      {status === "burn" && (
        <p
          onClick={() => {
            onChange(Number(total));
          }}>
          Max
        </p>
      )}
    </div>
  );
};

import { Address, beginCell, Cell, toNano } from "ton";
import { ContractDeployer } from "./contract-deployer";

import {
  createDeployParams,
  waitForContractDeploy,
  waitForSeqno,
  createPoolDeployParams,
} from "./utils";
import { zeroAddress } from "./utils";
import {
  buildJettonOnchainMetadata,
  burn,
  mintBody,
  transfer,
  updateMetadataBody,
} from "./jetton-minter";
import { readJettonMetadata, changeAdminBody, JettonMetaDataKeys } from "./jetton-minter";
import { getClientTon, getClientNow } from "./get-ton-client";
import { cellToAddress, makeGetCall } from "./make-get-call";
import { SendTransactionRequest, TonConnectUI } from "@tonconnect/ui-react";
import { DEX, pTON } from "@ston-fi/sdk";
import { Address as Address1 } from "@ton/ton";
export const JETTON_DEPLOY_GAS = toNano(0.3);
export enum JettonDeployState {
  NOT_STARTED,
  BALANCE_CHECK,
  UPLOAD_IMAGE,
  UPLOAD_METADATA,
  AWAITING_MINTER_DEPLOY,
  AWAITING_JWALLET_DEPLOY,
  VERIFY_MINT,
  ALREADY_DEPLOYED,
  DONE,
}

export interface JettonDeployParams {
  onchainMetaData?: {
    name: string;
    symbol: string;
    description?: string;
    image?: string;
    decimals?: string;
  };
  offchainUri?: string;
  owner: Address;
  amountToMint: any;
}

class JettonDeployController {
  async createJetton(
    params: JettonDeployParams,
    tonConnection: TonConnectUI,
    walletAddress: string,
    network: string,
  ): Promise<Address> {
    const contractDeployer = new ContractDeployer();
    const tc = await getClientTon(network);
    // params.onProgress?.(JettonDeployState.BALANCE_CHECK);
    const balance = await tc.getBalance(params.owner);
    if (balance.lt(JETTON_DEPLOY_GAS)) {
      return null as any;
    }
    const deployParams: any = createDeployParams(params, params.offchainUri);
    const contractAddr = contractDeployer.addressForContract(deployParams);

    if (await tc.isContractDeployed(contractAddr)) {
      // params.onProgress?.(JettonDeployState.ALREADY_DEPLOYED);
    } else {
      await contractDeployer.deployContract(deployParams, tonConnection);
      // params.onProgress?.(JettonDeployState.AWAITING_MINTER_DEPLOY);
      await waitForContractDeploy(contractAddr, tc);
    }

    const ownerJWalletAddr = await makeGetCall(
      contractAddr,
      "get_wallet_address",
      [beginCell().storeAddress(params.owner).endCell()],
      ([addr]) => (addr as Cell).beginParse().readAddress()!,
      tc,
    );

    // params.onProgress?.(JettonDeployState.AWAITING_JWALLET_DEPLOY);
    await waitForContractDeploy(ownerJWalletAddr, tc);
    // params.onProgress?.(
    //   JettonDeployState.VERIFY_MINT,
    //   undefined,
    //   contractAddr.toFriendly()
    // ); // TODO better way of emitting the contract?

    // params.onProgress?.(JettonDeployState.DONE);
    return contractAddr;
  }

  async burnAdmin(
    contractAddress: Address,
    tonConnection: TonConnectUI,
    walletAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: contractAddress.toString(),
          amount: toNano(0.01).toString(),
          stateInit: undefined,
          payload: changeAdminBody(zeroAddress()).toBoc().toString("base64"),
        },
      ],
    };

    await tonConnection.sendTransaction(tx);

    await waiter();
  }

  async mint(
    tonConnection: TonConnectUI,
    jettonMaster: Address,
    amount: any,
    walletAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: jettonMaster.toString(),
          amount: toNano(0.04).toString(),
          stateInit: undefined,
          payload: mintBody(Address.parse(walletAddress), amount, toNano(0.02), 0)
            .toBoc()
            .toString("base64"),
        },
      ],
    };
    await tonConnection.sendTransaction(tx);
    await waiter();
  }

  async transfer(
    tonConnection: TonConnectUI,
    amount: any,
    toAddress: string,
    fromAddress: string,
    ownerJettonWallet: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(fromAddress),
      }),
    );
    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: ownerJettonWallet,
          amount: toNano(0.05).toString(),
          stateInit: undefined,
          payload: transfer(Address.parse(toAddress), Address.parse(fromAddress), amount)
            .toBoc()
            .toString("base64"),
        },
      ],
    };
    await tonConnection.sendTransaction(tx);
    await waiter();
  }

  async burnJettons(
    tonConnection: TonConnectUI,
    amount: any,
    jettonAddress: string,
    walletAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: jettonAddress,
          amount: toNano(0.031).toString(),
          stateInit: undefined,
          payload: burn(amount, Address.parse(walletAddress)).toBoc().toString("base64"),
        },
      ],
    };

    await tonConnection.sendTransaction(tx);

    await waiter();
  }

  async getJettonDetails(contractAddr: Address, owner: Address, network: string) {
    const tc = await getClientTon(network);
    const minter = await makeGetCall(
      contractAddr,
      "get_jetton_data",
      [],
      async ([totalSupply, __, adminCell, contentCell]) => ({
        ...(await readJettonMetadata(contentCell as unknown as Cell)),
        admin: cellToAddress(adminCell),
        totalSupply: totalSupply as any,
      }),
      tc,
    );

    const jWalletAddress = await makeGetCall(
      contractAddr,
      "get_wallet_address",
      [beginCell().storeAddress(owner).endCell()],
      ([addressCell]) => cellToAddress(addressCell),
      tc,
    );

    const isDeployed = await tc.isContractDeployed(jWalletAddress);

    let jettonWallet;
    if (isDeployed) {
      jettonWallet = await makeGetCall(
        jWalletAddress,
        "get_wallet_data",
        [],
        ([amount, _, jettonMasterAddressCell]) => ({
          balance: amount as unknown as any,
          jWalletAddress,
          jettonMasterAddress: cellToAddress(jettonMasterAddressCell),
        }),
        tc,
      );
    } else {
      jettonWallet = null;
    }

    return {
      minter,
      jettonWallet,
    };
  }

  async fixFaultyJetton(
    contractAddress: Address,
    data: {
      [s in JettonMetaDataKeys]?: string | undefined;
    },
    connection: TonConnectUI,
    walletAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const body = updateMetadataBody(buildJettonOnchainMetadata(data));
    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: contractAddress.toString(),
          amount: toNano(0.01).toString(),
          stateInit: undefined,
          payload: body.toBoc().toString("base64"),
        },
      ],
    };
    await connection.sendTransaction(tx);
    await waiter();
  }

  async updateMetadata(
    contractAddress: Address,
    data: {
      [s in JettonMetaDataKeys]?: string | undefined;
    },
    connection: TonConnectUI,
    walltAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walltAddress),
      }),
    );

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: contractAddress.toString(),
          amount: toNano(0.01).toString(),
          stateInit: undefined,
          payload: updateMetadataBody(buildJettonOnchainMetadata(data)).toBoc().toString("base64"),
        },
      ],
    };

    await connection.sendTransaction(tx);

    await waiter();
  }
}

export interface PoolDeployParams {
  owner: Address;
  routerAddress: Address;
  lpFee: any;
  protocolFee: any;
  refFee: any;
  protocolFeesAddress: Address;
  collectedTokenAProtocolFees: any;
  collectedTokenBProtocolFees: any;
  reserve0: any;
  reserve1: any;
  wallet0: Address;
  wallet1: Address;
  supplyLP: any;
}

class PoolDeployController {
  async createPool(
    params: PoolDeployParams,
    tonConnection: TonConnectUI,
    walletAddress: string,
    network: string,
  ): Promise<Address> {
    const contractDeployer = new ContractDeployer();
    const tc = await getClientTon(network);
    // params.onProgress?.(JettonDeployState.BALANCE_CHECK);
    const balance = await tc.getBalance(params.owner);
    if (balance.lt(JETTON_DEPLOY_GAS)) {
      return null as any;
      // throw new Error("Not enough balance in deployer wallet");
    }
    const deployParams: any = createPoolDeployParams(params);
    const contractAddr = contractDeployer.addressForContract(deployParams);
    if (await tc.isContractDeployed(contractAddr)) {
      // params.onProgress?.(JettonDeployState.ALREADY_DEPLOYED);
    } else {
      await contractDeployer.deployPoolContract(deployParams, tonConnection);
      // params.onProgress?.(JettonDeployState.AWAITING_MINTER_DEPLOY);
      await waitForContractDeploy(contractAddr, tc);
    }

    // params.onProgress?.(JettonDeployState.AWAITING_JWALLET_DEPLOY);
    await waitForContractDeploy(contractAddr, tc);
    // params.onProgress?.(
    //   JettonDeployState.VERIFY_MINT,
    //   undefined,
    //   contractAddr.toFriendly()
    // ); // TODO better way of emitting the contract?

    // params.onProgress?.(JettonDeployState.DONE);
    return contractAddr;
  }

  async provideLiquidity(
    tonConnection: TonConnectUI,
    tokenA: string,
    tokenB: string,
    amountA: string,
    amountB: string,
    routerAddress: string,
    walletAddress: string,
    ptonAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const client = await getClientNow(network);
    const router = client.open(new DEX.v1.Router(Address1.parse(routerAddress)));
    let txsParams = [];
    if (ptonAddress === tokenA) {
      txsParams.push(
        await router.getProvideLiquidityTonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          proxyTon: new pTON.v1(tokenA),
          sendAmount: amountA,
          otherTokenAddress: tokenB,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    } else {
      txsParams.push(
        await router.getProvideLiquidityJettonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          sendTokenAddress: tokenA,
          sendAmount: amountA,
          otherTokenAddress: tokenB,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    }
    if (ptonAddress === tokenB) {
      txsParams.push(
        await router.getProvideLiquidityTonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          proxyTon: new pTON.v1(tokenB),
          sendAmount: amountB,
          otherTokenAddress: tokenA,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    } else {
      txsParams.push(
        await router.getProvideLiquidityJettonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          sendTokenAddress: tokenB,
          sendAmount: amountB,
          otherTokenAddress: tokenA,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    }
    txsParams = await Promise.all(txsParams);
    let sendMessages: any = [];

    for (let i = 0; i < txsParams.length; i++) {
      const txParams = txsParams[i];
      sendMessages.push({
        amount: txParams.value.toString(),
        address: txParams.to.toString(),
        stateInit: undefined,
        payload: (txParams.body as any).toBoc().toString("base64"),
      });
    }
    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: sendMessages,
    };
    await tonConnection.sendTransaction(tx);
    await waiter();
  }

  async refundLiquidity(
    tonConnection: TonConnectUI,
    tokenA: string,
    tokenB: string,
    routerAddress: string,
    walletAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);

    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const client = await getClientNow(network);
    const router = client.open(new DEX.v1.Router(Address1.parse(routerAddress)));
    const pool = client.open(
      await router.getPool({
        token0: tokenA, // STON
        token1: tokenB, // GEMSTON
      }),
    );
    const lpAccount = client.open(
      await pool.getLpAccount({
        ownerAddress: walletAddress, // ! replace with your address
      }),
    );
    const txParams = await lpAccount.getRefundTxParams();

    let sendMessages: any = [];

    sendMessages.push({
      amount: txParams.value.toString(),
      address: txParams.to.toString(),
      stateInit: undefined,
      payload: (txParams.body as any).toBoc().toString("base64"),
    });

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: sendMessages,
    };

    await tonConnection.sendTransaction(tx);

    await waiter();
  }

  async burnLiquidityTokens(
    tonConnection: TonConnectUI,
    tokenA: string,
    tokenB: string,
    routerAddress: string,
    walletAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);

    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const client = await getClientNow(network);
    const router = client.open(new DEX.v1.Router(Address1.parse(routerAddress)));
    const pool = client.open(
      await router.getPool({
        token0: tokenA, // STON
        token1: tokenB, // GEMSTON
      }),
    );
    const lpTokenWallet = client.open(
      await pool.getJettonWallet({
        ownerAddress: walletAddress,
      }),
    );
    const lpTokenWalletData = await lpTokenWallet.getWalletData();

    const txParams = await pool.getBurnTxParams({
      amount: lpTokenWalletData.balance,
      responseAddress: Address.parse(walletAddress).toFriendly(),
      queryId: Math.floor(Math.random() * Math.pow(2, 31)),
    });
    let sendMessages: any = [];

    sendMessages.push({
      amount: txParams.value.toString(),
      address: txParams.to.toString(),
      stateInit: undefined,
      payload: (txParams.body as any).toBoc().toString("base64"),
    });

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: sendMessages,
    };

    await tonConnection.sendTransaction(tx);

    await waiter();
  }

  async tokenAProvideLiquidity(
    tonConnection: TonConnectUI,
    tokenA: string,
    tokenB: string,
    amountA: string,
    amountB: string,
    routerAddress: string,
    walletAddress: string,
    ptonAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const client = await getClientNow(network);
    const router = client.open(new DEX.v1.Router(Address1.parse(routerAddress)));

    let txsParams = [];

    if (ptonAddress === tokenA) {
      txsParams.push(
        await router.getProvideLiquidityTonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          proxyTon: new pTON.v1(tokenA),
          sendAmount: amountA,
          otherTokenAddress: tokenB,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    } else {
      txsParams.push(
        await router.getProvideLiquidityJettonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          sendTokenAddress: tokenA,
          sendAmount: amountA,
          otherTokenAddress: tokenB,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    }

    txsParams = await Promise.all(txsParams);
    let sendMessages: any = [];

    for (let i = 0; i < txsParams.length; i++) {
      const txParams = txsParams[i];
      sendMessages.push({
        amount: txParams.value.toString(),
        address: txParams.to.toString(),
        stateInit: undefined,
        payload: (txParams.body as any).toBoc().toString("base64"),
      });
    }

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: sendMessages,
    };

    await tonConnection.sendTransaction(tx);

    await waiter();
  }

  async tokenBProvideLiquidity(
    tonConnection: TonConnectUI,
    tokenA: string,
    tokenB: string,
    amountA: string,
    amountB: string,
    routerAddress: string,
    walletAddress: string,
    ptonAddress: string,
    network: string,
  ) {
    const tc = await getClientTon(network);
    const waiter = await waitForSeqno(
      tc.openWalletFromAddress({
        source: Address.parse(walletAddress),
      }),
    );
    const client = await getClientNow(network);
    const router = client.open(new DEX.v1.Router(Address1.parse(routerAddress)));
    let txsParams = [];
    if (ptonAddress === tokenB) {
      txsParams.push(
        await router.getProvideLiquidityTonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          proxyTon: new pTON.v1(tokenB),
          sendAmount: amountB,
          otherTokenAddress: tokenA,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    } else {
      txsParams.push(
        await router.getProvideLiquidityJettonTxParams({
          userWalletAddress: Address.parse(walletAddress).toFriendly(),
          sendTokenAddress: tokenB,
          sendAmount: amountB,
          otherTokenAddress: tokenA,
          minLpOut: "1",
          queryId: Math.floor(Math.random() * Math.pow(2, 31)),
        }),
      );
    }

    txsParams = await Promise.all(txsParams);
    let sendMessages: any = [];

    for (let i = 0; i < txsParams.length; i++) {
      const txParams = txsParams[i];
      sendMessages.push({
        amount: txParams.value.toString(),
        address: txParams.to.toString(),
        stateInit: undefined,
        payload: (txParams.body as any).toBoc().toString("base64"),
      });
    }

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: sendMessages,
    };

    await tonConnection.sendTransaction(tx);

    await waiter();
  }
}

const jettonDeployController = new JettonDeployController();
const poolDeployController = new PoolDeployController();
export { jettonDeployController, poolDeployController };

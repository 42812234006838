import axios from "axios";
import dayjs from "dayjs";
const request = axios.create({
  // baseURL: "http://165.22.51.161:8081",
  baseURL: "https://ton-minter-f9uf.vercel.app/",
});

request.interceptors.request.use(
  (config) => {
    return config;
  },
  () => {
    return null;
  },
);
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (e: any) => {
    return e;
  },
);
// && cp build/index.html build/404.html
const Request = () => {
  const get = async (params: any, token?: any) => {
    const data = await request({
      ...params,
      headers: {
        "Content-Type": "application/json", // 根据需要添加其他标头
        Authorization: token,
        "x-chainId": "-2",
        "x-app": "dexpert",
        "x-chainName": "ton",
        network: params?.network,
      },
    });
    return data;
  };
  const allAxios = async (params: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedToken = JSON.parse(atob(base64));
      if (decodedToken?.exp && dayjs(dayjs.unix(decodedToken?.exp)).isAfter(dayjs())) {
        return get(params, token);
      } else {
        return "clear";
      }
    } else {
      return get(params);
    }
  };
  return { allAxios };
};

export default Request;

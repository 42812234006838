import Wallet from "assets/icons/walletTon.svg";
import Back from "assets/icons/backRights.svg";
import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";
export default function ItemToken({
  data,
  name,
  hidden,
  isShow,
  setIisStatus,
  setIsModalOpen,
  changeValue,
  isSubmit,
}) {
  const { t } = useTranslation();
  return (
    <div className="token1">
      <div className="balance">
        <span>
          {t("page.assets")} {name}
        </span>
        <div>
          <p>
            <img src={Wallet} alt="" />
            <span>{data?.balance}</span>
          </p>
        </div>
      </div>
      <div className="token">
        <div className="left">
          {data?.jetton?.image ? (
            <img src={data?.jetton?.image} style={{ width: "40px", borderRadius: "50%" }} alt="" />
          ) : (
            <p></p>
          )}
          <p
            onClick={() => {
              if (!hidden) {
                if (name === "2" || (name === "1" && !isShow)) {
                  setIisStatus(name);
                  setIsModalOpen(true);
                }
              }
            }}>
            {data?.jetton?.symbol}
            {(name === "2" || (name === "1" && !isShow)) && !hidden && <img src={Back} alt="" />}
          </p>
        </div>
        <div className="right">
          <InputNumber
            className="inputBalance"
            controls={false}
            placeholder="0.00"
            value={data?.value || ""}
            onChange={(e) => {
              if (!isSubmit) {
                if (Number(e) > Number(data?.balance)) {
                  changeValue(name, data?.balance);
                } else {
                  changeValue(name, e);
                }
              }
            }}
            stringMode
          />
          <p
            onClick={() => {
              if (!isSubmit) {
                changeValue(name, data?.balance);
              }
            }}>
            {t("page.max")}
          </p>
        </div>
      </div>
    </div>
  );
}

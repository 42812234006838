import { Input } from "antd";
import Search from "assets/icons/searchLogo.svg";
import { useEffect, useState } from "react";
import Nodata from "components/Nodata";
import axios from "axios";
import { useContext } from "react";
import { networkConfig } from "constan";
import { useTranslation } from "react-i18next";
import { useTonAddress } from "@tonconnect/ui-react";
import { EnvContextParams, EnvContext } from "App";
import useNotification from "hooks/useNotification";
import { throttle } from "radash";
import { isValidAddress } from "utils/index";
import Decimal from "decimal.js";
Decimal.set({ toExpPos: 24, precision: 24 });
export default function Modal({ handleCancel, allToken, poolAllToken, isStatus, setPoolAllToken }) {
  const { network }: EnvContextParams = useContext(EnvContext);
  const walletAddress = useTonAddress();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [token, setToken] = useState([]);
  const [value, setValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  useEffect(() => {
    if (allToken?.length > 0) {
      setToken(allToken);
    }
  }, [allToken]);
  const changeToken = (i: any) => {
    const data =
      poolAllToken?.tokenA?.jetton === i?.jetton || poolAllToken?.tokenB?.jetton === i?.jetton;
    return data;
  };
  const change = (e: any) => {
    if (!e.target.value) {
      setToken(allToken);
    }
    setValue(e.target.value);
  };
  const search = throttle({ interval: 3000 }, async (e) => {
    try {
      if (!isSearch) {
        if (e?.type === "click" || (e?.type === "keydown" && e?.key === "Enter")) {
          if (isValidAddress(value)) {
            setIsSearch(true);
            const jetton: any = await axios({
              method: "get",
              url:
                networkConfig[network].url + "/v2/accounts/" + walletAddress + "/jettons/" + value,
              params: { currencies: "usd", account_id: walletAddress, jetton_id: value },
            });
            if (jetton?.status === 200) {
              const balance = new Decimal(jetton?.data?.balance).div(
                new Decimal(10).pow(jetton?.data?.jetton?.decimals),
              );
              setToken([{ ...jetton?.data, balance: balance.toString() }]);
              setIsSearch(false);
            } else {
              setIsSearch(false);
            }
          } else {
            showNotification("输入地址错误", "error");
          }
        }
      }
    } catch (e) {
      return null;
    }
  });

  return (
    <div className="tokenBox">
      <div className="top">
        <span> {t("page.Select")}</span>
        <span onClick={handleCancel}>x</span>
      </div>
      <Input
        onChange={change}
        className="inputToken"
        onKeyDown={search}
        allowClear
        suffix={
          <img src={Search} onClick={search} alt="" style={{ width: "20px", cursor: "pointer" }} />
        }
        placeholder={t("page.Input")}
      />
      {/* 选项 */}
      <div className="option">
        <div>{t("page.balance")}</div>
      </div>
      <div
        style={{ maxHeight: "38vh", overflow: "hidden auto", minHeight: "30vh" }}
        className="modal">
        {/* list */}
        {token.length > 0 ? (
          token.map((i, index) => {
            return (
              <div
                className="tokenItem"
                style={{
                  border: changeToken(i) ? "1px solid  rgb(134,240,151)" : "1px solid transparent",
                }}
                key={index}
                onClick={() => {
                  if (!changeToken(i)) {
                    if (isStatus === "1") {
                      setPoolAllToken({ ...poolAllToken, tokenA: { ...i, value: "0" } });
                    } else {
                      setPoolAllToken({ ...poolAllToken, tokenB: { ...i, value: "0" } });
                    }
                    handleCancel();
                  }
                }}>
                <div className="left">
                  <img src={i?.jetton?.image} alt="" />
                  <div>
                    <p>{i?.jetton?.name}</p>
                    <p>{i?.jetton?.symbol}</p>
                  </div>
                </div>
                <div className="right">
                  <div>
                    <p>{i?.balance}</p>
                    <p></p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Nodata />
        )}
      </div>
    </div>
  );
}

import React, { ReactNode } from "react";
import { CircularProgress, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadImg from "components/allLoad/loadImg";

interface StyledButtonProps {
  fontSize?: number;
  fontWeight?: number;
  transparent?: string;
  background?: string;
  width?: any;
  height?: any;
  border?: any;
}
const StyledButton = styled(LoadingButton)((props: StyledButtonProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  padding: "10px 16px",
  margin: "auto",
  maxWidth: 160,
  width: props.width || "100%",
  height: props.height || "100%",
  fontSize: props.fontSize || 16,
  boxShadow: "none",
  fontWeight: props.fontWeight || 600,
  borderRadius: 20,
  background: props.background || "rgb(134,240,151)",
  border: props.border || "1px solid transparent",
  whiteSpace: "nowrap",
  cursor: "pointer",
  color: props.border ? "rgb(134,240,151)" : "black !important",
  "& img": {
    maxWidth: 22,
  },
  "&:disabled": {
    background: "#D9D9D9",
  },
  "&:hover": {
    background: props.background || "rgb(134,240,151)",
    border: props.border || "1px solid transparent",
  },
  "& .MuiLoadingButton-icon": {
    display: "none",
  },
}));

interface AppButtonProps extends StyledButtonProps {
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
}

export const AppButton: React.FC<AppButtonProps> = ({
  children,
  loading,
  disabled,
  onClick,
  type = "button",
  fontSize = 16,
  transparent,
  background,
  width,
  height = "auto",
  fontWeight,
  border,
}) => {
  return (
    <StyledButton
      width={width}
      height={height}
      fontSize={fontSize}
      fontWeight={fontWeight}
      border={border}
      transparent={transparent}
      background={background}
      className={children !== "Update metadata" ? "base-button" : ""}
      type={type}
      onClick={onClick ? onClick : () => {}}
      variant={transparent === "true" ? "outlined" : "contained"}
      loading={loading}
      disabled={disabled}
      disableElevation
      loadingIndicator={<LoadImg />}>
      {loading ? "" : children}
    </StyledButton>
  );
};

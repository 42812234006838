import React, { useState } from "react";
import { AppHeading } from "components/appHeading";
import { StyledBlock, StyledCategoryFields, StyledBlockMore } from "pages/jetton/styled";
import { DataRow } from "pages/jetton/dataRow";
import BigNumberDisplay from "components/BigNumberDisplay";
import { balanceActions } from "pages/jetton/util";
import { TransferAction } from "pages/jetton/actions/transfer";
import CreatePoolAction from "pages/jetton/actions/CreatePoolAction";
import useJettonStore from "store/jetton-store/useJettonStore";
import { useTonAddress } from "@tonconnect/ui-react";
import ConnectAction from "pages/jetton/actions/ConnectAction";
import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CenteringWrapper } from "components/header/headerSearchBar/styled";
import { CheckWalletBalancePopup } from "components/checkWalletBalancePopup";
import ProvideLiquidityAction from "../actions/ProvideLiquidityAction";
import RefundLiquidityAction from "pages/jetton/actions/RefundLiquidityAction";
import BurnLiquidityTokensAction from "pages/jetton/actions/BurnLiquidityTokensAction";
import TokenAProvideLiquidityAction from "pages/jetton/actions/TokenAProvideLiquidityAction";
import TokenBProvideLiquidityAction from "pages/jetton/actions/TokenBProvideLiquidityAction";
export const Wallet = () => {
  const { balance, symbol, jettonLoading, selectedWalletAddress, decimals } = useJettonStore();
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const address = useTonAddress();
  const history = useNavigate();

  return (
    <StyledBlockMore sx={{ width: "calc(45% - 15px)" }}>
      <StyledBlock sx={{ width: "100%", height: "100%" }}>
        <AppHeading
          text={`${address ? t("page.Connected") : ""} ${t("page.Jetton")}`}
          variant="h4"
          fontWeight={800}
          fontSize={20}
          marginBottom={20}
          color="#fff"
        />
        <StyledCategoryFields>
          <DataRow
            title={t("page.WalletAddress")}
            value={selectedWalletAddress}
            dataLoading={jettonLoading}
            address={selectedWalletAddress}
            regularAddress
            description={t("page.transfers")}
            children={
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <CheckWalletBalancePopup showPopup={showPopup} close={() => setShowPopup(false)} />
                {t("page.WalletAddress")}
                <CenteringWrapper>
                  {/* {address ? (
                    <Link sx={{ cursor: "pointer" }} onClick={() => history("/")}>
                      {t("page.Cancel")}
                    </Link>
                  ) : (
                    <Link sx={{ cursor: "pointer" }} onClick={() => setShowPopup(true)}>
                      View different wallet balance
                    </Link>
                  )} */}
                </CenteringWrapper>
              </Box>
            }
          />
          <DataRow
            title={t("page.WalletBalance")}
            value={
              balance && (
                <>
                  <BigNumberDisplay value={balance} decimals={decimals} /> {symbol}
                </>
              )
            }
            dataLoading={jettonLoading}
            actions={balanceActions}
            description={t("page.Number")}
          />
          <TransferAction />
        </StyledCategoryFields>
        {!selectedWalletAddress && !jettonLoading && !address && (
          <div style={{ marginTop: "20px" }}>
            <ConnectAction />
          </div>
        )}
        {/* 创建池子 */}
        {/* <CreatePoolAction /> */}
        {/* <ProvideLiquidityAction /> */}
        {/* <RefundLiquidityAction /> */}
        {/* <BurnLiquidityTokensAction /> */}
        {/* <TokenAProvideLiquidityAction /> */}
        {/* <TokenBProvideLiquidityAction /> */}
      </StyledBlock>
      {/* <SetUpPool /> */}
    </StyledBlockMore>
  );
};

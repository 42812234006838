import { ReactNode } from "react";
interface Props {
  children: ReactNode;
  zeroPadding?: boolean;
}
function FieldDescription({ children, zeroPadding }: Props) {
  return (
    <div
      style={{
        fontSize: 14,
        marginTop: 5,
        display: "flex",
        paddingLeft: zeroPadding ? 0 : 18,
        color: "rgba(255, 255, 255, 0.55)",
      }}>
      {children}
    </div>
  );
}

export default FieldDescription;

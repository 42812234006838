import { TonClient } from "@ton/ton";
import { TonClient as TonClientAll } from "ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";
export const getClientTon = async (network: any) => {
  const endpointP = getHttpEndpoint({
    network: network,
  });
  const client = new TonClientAll({
    endpoint: await endpointP,
  });
  return client;
};

export const getClientNow = async (network: any) => {
  const endpointP = getHttpEndpoint({
    network: network,
  });
  const client = new TonClient({
    endpoint: await endpointP,
  });
  return client;
};

import "./index.scss";
import Back from "assets/icons/Union.svg";
import BackNow from "assets/icons/Groupo.svg";

function Loading({ status, browser }: any) {
  const changeWidth = () => {
    if (status === "none") {
      if (browser) {
        return "auto";
      } else {
        return window.innerWidth + "px";
      }
    } else {
      if (browser) {
        return "auto";
      } else {
        return window.innerWidth + "px";
      }
    }
  };
  return (
    <div
      className={"disCen"}
      style={{
        marginTop: status === "none" ? "0" : status === "20" ? "10%" : "40%",
        width: "100%",
      }}>
      <div className="animationDex">
        <div>
          <img src={Back} alt="" className="bigImg" style={{ width: "50px" }} />
          <img src={BackNow} alt="" className="smallImg" style={{ width: "22px" }} />
        </div>
      </div>
    </div>
  );
}

export default Loading;
